import axios from 'axios';

// util
import { API_URL_NEW } from '../lib/endpoints';
import { prepareSelectParam, prepareSortParam } from '../lib/queryParams';

const startupMarketMapApi = {
    getYearlyFundingData: (filters) => {
        const paramFields = ['year', 'mega', 'total_mega', 'disclosed','amount_excluding_mega', 'total_disclosed', 'undisclosed', 'total_undisclosed', 'deals', 'total', 'valuation', 'valuation_trend'];
        const fields = prepareSelectParam(paramFields);
        let url = `${API_URL_NEW}/network-map/funding-yearly?fields=${fields}&filters=${filters}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {

                    // sort records as years are not always in order
                    const data = response.data.data.sort((a, b) => a.year - b.year);

                    resolve({
                        records: data,
                        count: response.data.navigation.total
                    });

                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getPrimaryIndustryFundingData: (filters = "", requestFields = ['primary_industry_name', 'primary_industry']) => {
        const paramFields = ['mega', 'total_mega', 'disclosed','amount_excluding_mega','total_disclosed', 'undisclosed', 'total_undisclosed', 'deals', 'total', ...requestFields];
        const fields = prepareSelectParam(paramFields);
        const sortParams = [{ key: 'total', sortVal: 'desc' }];
        const sortFilter = prepareSortParam(sortParams)
        let url = `${API_URL_NEW}/network-map/funding-primary-industry?fields=${fields}&filters=${filters}&sort=${sortFilter}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getFundedStartupCount: (filters) => {
        const paramFields = ['founded_year', 'startup_founded', 'startup_funded'];
        const fields = prepareSelectParam(paramFields);
        let url = `${API_URL_NEW}/network-map/funded-startup?fields=${fields}&filters=${filters}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getExitsByIndustryData: (filters) => {
        const paramFields = ['primary_industry_name', 'primary_industry', 'acquisitions'];
        const fields = prepareSelectParam(paramFields);
        const sortParams = [{ key: 'acquisitions', sortVal: 'desc' }];
        const sortFilter = prepareSortParam(sortParams)
        const url = `${API_URL_NEW}/network-map/acquisitions-primary-industry?fields=${fields}&filters=${filters}&sort=${sortFilter}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getGeographyData: (filters) => {
        const paramFields = ['country', 'location_name', 'mega','amount_excluding_mega', 'total_mega', 'disclosed', 'total_disclosed', 'undisclosed', 'total_undisclosed', 'deals', 'total'];
        const fields = prepareSelectParam(paramFields);
        const sortParams = [{ key: 'total', sortVal: 'desc' }];
        const sortFilter = prepareSortParam(sortParams)
        const url = `${API_URL_NEW}/network-map/funding-country?fields=${fields}&filters=${filters}&sort=${sortFilter}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getExitsByCountryData: (filters) => {
        const paramFields = ['location_name', 'country', 'acquisitions'];
        const fields = prepareSelectParam(paramFields);
        const sortParams = [{ key: 'acquisitions', sortVal: 'desc' }];
        const sortFilter = prepareSortParam(sortParams)
        const url = `${API_URL_NEW}/network-map/acquisitions-country?fields=${fields}&filters=${filters}&sort=${sortFilter}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getExitsByYearData: (filters) => {
        const paramFields = ['year', 'acquisitions'];
        const fields = prepareSelectParam(paramFields);
        const sortParams = [{ key: 'year', sortVal: 'desc' }];
        const sortFilter = prepareSortParam(sortParams)
        const url = `${API_URL_NEW}/network-map/acquisitions-yearly?fields=${fields}&filters=${filters}&sort=${sortFilter}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getStartupDirectoryLandscape: (fields, filters, limit, page, sort) => {
        const url = `${API_URL_NEW}/network-map/directory?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    /*
     * get startup list
     * use case: table view & show more in landscape view
     */
    getStartups: (fields, filters, page, sort, limit) => {
        let url = `${API_URL_NEW}/startup?fields=${fields}&filters=${filters}&page=${page}&limit=${limit}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                resolve(response)
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    reject(err)
                }
            })
        })
    }
}

export default startupMarketMapApi;
