import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';

// api
import entityApi from '/src/apis/entityApi';
import taxonomyApi from '/src/apis/taxonomyApi';

// constants
import { API_URL_NEW, WEB_URL } from '/src/lib/endpoints';
import { TEAM_EDIT_ROLE_ID, TAXONOMIES, JOIN_FUNDING_ROUND_STATUS, ALLOWED_QUERY_OPERATOR, PLAY_STORE_LINK_REGEX, APP_STORE_LINK_REGEX, ITUNES_STORE_LINK_REGEX } from '/src/lib/constants';

// helpers
import { prepareSelectParam, prepareFilterParam } from '/src/lib/queryParams';
import { generateEntityCode, stringContainsURLs, getItemWebUrl } from '/src/lib/helpers';



class AddDefaultComponent extends Component {

    handleDelete = async (path) => {
        try {
            const config = {
                method: 'DELETE',
                url: `${API_URL_NEW}/${path}`,
            };

            const response = await axios(config);
            return response
        } catch (error) {
            return error
        }
    }

    handlePatch = async (path, body) => {
        try {
            const config = {
                method: 'PATCH',
                url: `${API_URL_NEW}/${path}`,
                data: body
            };
            const response = await axios(config);
            return response
        } catch (error) {
            return error
        }
    }

    handlePost = async (path, body) => {
        // return new Promise((fulfil, reject) => {
        try {
            const config = {
                method: 'POST',
                url: `${API_URL_NEW}/${path}`,
                data: body
            };
            const response = await axios(config);
            return response
        } catch (error) {
            return error
        }
    }

    editInviteTeamFinished = () => {
        this.setState({ tempName: "", tempRole: "", invalidname: false, isInviteSuccess: true });
        this.getTeam();
        // this.inviteSuccessPopup();
    }

    addInviteTeamFinished = (response) => {
        let { team, teamInvitations } = this.state;

        const tempObj = {
            isInvitation: true,
            name: `${response.firstName} ${response.lastName}`,
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            role: response.teamRole
        };

        team.unshift(tempObj);
        teamInvitations.push(tempObj);              // This would be used to avoid any duplicate emails/users
        this.setState({ team, teamInvitations, tempName: "", tempRole: "", invalidname: false, isTeamRequiredError: false });
    }

    /**
     * @param {String}  path        API Path
     * @param {String}  fieldName   FieldName in the backend
     * @param {Blob}    file        File data
     * @returns {Object}            API returned data
     */
    saveFile = async (path, fieldName, file) => {
        const formData = new FormData()
        formData.append(fieldName, file)

        try {
            const response = await axios.post(`${API_URL_NEW}/${path}`,
                formData, {
                    method: 'POST'
                });

            return response
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * @param {String}  path        API Path
     * @param {String}  fieldName   FieldName in the backend
     * @param {Blob}    file        File data
     * @returns {Object}            API returned data
     */
    updateFile = async (path, fieldName, file) => {
        const formData = new FormData()
        formData.append(fieldName, file)

        try {
            const response = await axios.patch(`${API_URL_NEW}/${path}`,
                formData, {
                    method: 'PATCH'
                });

            return response
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * This method will show popup on successfull response
     * "Successfull Response" means, if there wasn't any refresh token error
     * @param {Array} apiResponse
     * @param {Object} stateObj State object which has to be update the state
     */
    handleSuccessRes = (apiResponse, stateObj) => {
        if (!isEmpty(apiResponse) && apiResponse.filter(item => item.isRefreshTokenExpired).length > 0) {
            return false;
        }

        this.setState(stateObj, () => {
            $('#successModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            });
        });
    }

    handleInfoPopup = (message) => {
        this.setState({
            infoPopupMsg: <>{this.state.generalSuccessMsg}{message}</>,
            showInfoPopup: true
        })
        $('#infoModal').modal({
            backdrop: 'static', keyboard: true, show: true
        })
    }

    /**
     * @param {Array} data      Data Field from the state
     * @param {String} prop     Prop name which API requires
     * @returns {Array}
     */
    prepRelFields = (data, prop) => {
        let arReturn = [];

        if (data.length > 0) {
            data.map(v => {
                let tempObj = {};
                tempObj[prop] = v;
                arReturn.push(tempObj);
            });
        }

        return arReturn;
    }

    /**
     * @param {Array} data      Data Field from the state
     * @returns {Array}
     */
    prepInstFields = (data) => {
        let arReturn = [];

        if (data.length > 0) {
            data.map(v => {
                arReturn.push({
                    linked_id: v.linked_id,
                    linked_type: v.linked_type
                });
            });
        }

        return arReturn;
    }

    /**
     * Prepare data for team API
     * @returns {Array} Team data for API
     * @todo: Param id is temp should be removed
     * @todo: Add user as a team member
     */
    prepTeamFields = (id, entity_type) => {
        const { team } = this.state;
        const teamAr = []; // arReturn = [];
        const teamInvitationsAr = [];

        if (team.length > 0) {
            team.map(v => {
                if (v.isInvitation) {
                    teamInvitationsAr.push({
                        first_name: v.firstName,
                        last_name: v.lastName,
                        email: v.email,
                        team_role_id: v.role,
                        entity_id: id,
                        entity_type: entity_type
                    });
                } else {
                    teamAr.push({
                        entity_id: id,
                        entity_type: entity_type,
                        role_id: TEAM_EDIT_ROLE_ID,
                        user_id: v.uid,
                        team_role_id: v.role
                    });
                }
            });
        }

        // todo: this should be tested properly
        return { teamAr, teamInvitationsAr };
    }

    choseInvestor = (data) => {

        if (isEmpty(data)) return;

        const { type, id, title, email, isInvitation, code } = data;
        const tempObj = {};

        tempObj.tempInvestor = title;
        tempObj.tempInvestorNid = id;
        tempObj.tempInvestorType = type;
        tempObj.tempEmailRelations = email;
        tempObj.investorsPreload = [];
        tempObj.tempIsInvitation = isInvitation ? true : false;
        tempObj.isShowInvestorInvitation = false;
        tempObj.tempInvestorCode = code;

        this.setState(tempObj);

    };

    handleLogoUpload = async event => {
        const file = await this.onDrop(event);

        if (!file) {
            return
        }

        try {
            const response = await this.saveFile('file/logo', 'logo', file);
            if (response.status === 201) {
                this.setState({
                    logoId: response.data.data.id,
                    logo_error: false
                });
            }
        } catch (error) {
            this.setState({
                logoFile: '',
            })
        }
    }

    handleCoverUpload = async event => {
        const file = await this.onDrop(event);

        if (!file) {
            return
        }

        try {
            const response = await this.saveFile('file/cover-image', 'cover_image', file);
            if (response.status === 201) {
                this.setState({
                    coverId: response.data.data.id
                });
            }
        } catch (error) {
            this.setState({
                coverFile: '',
            })
        }
    }

    handleFileUpload = async event => {
        const file = await this.onDrop(event);

        try {
            const response = await this.saveFile('file/logo', 'logo', file);
            if (response.status === 201) {
                this.setState({
                    pictureFile: response.data.data.id,
                    pictureFileName: response.data.data.name
                });
            }
        } catch (error) {
            this.setState({
                pictureFile: '',
            })
        }
    }

    getTeam = () => {
        const entityType = this.entityType.toLowerCase();
        let teams = [];
        const fields = prepareSelectParam(['id', 'user_data', 'team_role_data', 'user_id', 'team_role_id']);

        axios.get(`${API_URL_NEW}/${entityType}/${this.id}/team?fields=${fields}`)
            .then(response => {
                if (response.data.data && response.data.data.length > 0) {
                    response = response.data.data;
                    response.map(v => {
                        teams.push({
                            id: v.id,
                            uid: v.user_id,
                            logo: !isEmpty(v.user_data) ? { path: v.user_data.profile_pic } : "",
                            name: !isEmpty(v.user_data) ? `${v.user_data.first_name} ${v.user_data.last_name}` : "",
                            role: !isEmpty(v.team_role_data) ? v.team_role_data.id : "",
                            user_data: v.user_data
                        })
                    });

                    this.setState({
                        team: [...this.state.team, ...teams],
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    getGeoFocus = () => {
        return new Promise((resolve, reject) => {

            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/geo-focus/?fields=location_data^,location_id^,id&limit=300`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let geoArr = [];
                        response.data.data.map(v => {
                            geoArr.push({
                                id: v.id,
                                itemId: v.location_id,
                                name: v.location_data.name
                            });
                        });

                        this.setState({
                            geofocus: geoArr[geoArr.length - 1].itemId,
                            geofocusArr: geoArr,
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        })
    }

    getIndustries = () => {
        return new Promise((resolve, reject) => {

            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/industry/?fields=industry_data^,industry_id^,id^,is_primary^,is_sub_industry&limit=300`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let focusArr = [];
                        let subFocusArr = [];
                        let primaryFocus = "";

                        response.data.data.map(v => {
                            if (v.is_primary == 1) {
                                primaryFocus = v.industry_id;
                            }
                            else if (v.is_sub_industry === 0) {
                                focusArr.push({
                                    id: v.id,
                                    itemId: v.industry_id,
                                    name: v.industry_data.name
                                });
                            }
                            else if (v.is_sub_industry === 1) {
                                subFocusArr.push({
                                    id: v.id,
                                    itemId: v.industry_id,
                                    name: v.industry_data.name
                                });
                            }
                        });

                        // Show the last entry in sub industry array as Sub Industry
                        let subIndustry = null;
                        if (subFocusArr[subFocusArr.length - 1]) {
                            subIndustry = {
                                value: subFocusArr[subFocusArr.length - 1].itemId,
                                label: subFocusArr[subFocusArr.length - 1].name
                            }
                        }

                        this.setState({
                            focus: focusArr.length > 0 ? focusArr[focusArr.length - 1].itemId : '',
                            focusArr,
                            subFocusArr,
                            subIndustry,
                            primaryFocus
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        })

    }

    getBusinessTypes = () => {
        return new Promise((resolve, reject) => {
            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/business-type/?fields=business_type_id^,business_type_data^,id`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let typeArr = [];
                        response.data.data.map(v => {
                            typeArr.push({
                                id: v.id,
                                itemId: v.business_type_id,
                                name: v.business_type_data.name
                            });
                        });

                        this.setState({
                            type: typeArr[typeArr.length - 1].itemId,
                            typeArr: typeArr,
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        });
    }

    getBranches = () => {
        return new Promise((resolve, reject) => {
            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/branch/?fields=location_id^,id^,location_data`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let branchesArr = [];
                        response.data.data.map(v => {
                            branchesArr.push({
                                id: v.id,
                                itemId: v.location_id,
                                name: v.location_data.name
                            });
                        });

                        this.setState({
                            branches: branchesArr[branchesArr.length - 1].itemId,
                            branchesArr: branchesArr,
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        });
    }

    getSupport = () => {
        return new Promise((resolve, reject) => {
            const { supportData } = this.state;
            const fields = prepareSelectParam(['id', 'support_type_id', 'support_type_data']);
            const entityType = this.entityType.toLowerCase();
            let tempSupportData = [];

            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/support/?fields=${fields}`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let supportArr = [];
                        response.data.data.map(v => {
                            supportArr.push({
                                id: v.id,
                                itemId: v.support_type_data.id,
                                name: v.support_type_data.name
                            });

                            supportData.map(subv => {
                                if (subv.id === v.support_type_data.id) {
                                    subv.isSelected = true;
                                } else {
                                    subv.isSelected = false;
                                }
                                tempSupportData.push(subv);
                            });

                        });

                        this.setState({
                            supportArr: supportArr,
                            //supportData: tempSupportData
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        });
    }

    getProductFiles = () => {
        const entityType = this.entityType.toLowerCase();

        axios.get(`${API_URL_NEW}/${entityType}/${this.id}/product-file/?fields=file_id^,product_file_data`)
            .then(response => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({
                        picturePath: response.data.data[0].product_file_data.path,
                        pictureFile: response.data.data[0].product_file_data.name,
                        pictureId: response.data.data[0].file_id
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    getDevStage = () => {
        return new Promise((resolve, reject) => {
            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/dev-stage/?fields=development_stage_data^,id`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let stageArr = [];
                        response.data.data.map(v => {
                            stageArr.push({
                                id: v.id,
                                itemId: v.development_stage_data.id,
                                name: v.development_stage_data.name
                            });
                        });

                        this.setState({
                            stage: stageArr[stageArr.length - 1].itemId,
                            stageArr: stageArr,
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        })
    }

    getInstitutions = () => {
        return new Promise((resolve, reject) => {
            const entityType = this.entityType.toLowerCase();
            axios.get(`${API_URL_NEW}/${entityType}/${this.id}/institution/?fields=linked_data^,id`)
                .then(response => {
                    if (response.data.data && response.data.data.length > 0) {
                        let instArr = [];
                        response.data.data.map(v => {

                            // handle invalid data (testapp)
                            if (!v.linked_data) {
                                return;
                            }

                            instArr.push({
                                id: v.linked_data.id,
                                linked_id: v.linked_data.id,
                                linked_type: v.linked_data.type,
                                name: v.linked_data.name,
                                code: v.linked_data.code,
                                isExisting: true
                            });
                        });

                        this.setState({
                            institution: instArr.length > 0 ? instArr[instArr.length - 1].name : '',
                            institutionArr: instArr,
                        }, () => resolve());
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject();
                })
        })
    }

    getStartupRelations = () => {
        const entityType = this.entityType.toLowerCase();
        const URL = `${API_URL_NEW}/${entityType}/${this.id}/startup-rel/?fields=startup_data^,id&limit=150`;
        axios.get(URL).then(response => {
            if (response.data.data && response.data.data.length > 0) {
                let startupRelArr = [];
                response.data.data.map(v => {
                    startupRelArr.push({
                        relId: v.id,
                        itemId: v.startup_data.id,
                        name: v.startup_data.name
                    });
                });

                // sort alphabetically so it's easier for users to find
                startupRelArr.sort((a, b) => a.name.localeCompare(b.name));

                this.setState({
                    startupRel: startupRelArr[startupRelArr.length - 1].itemId,
                    startups: startupRelArr,
                });
            }
        })
            .catch(err => {
                console.log(err);
            })
    }

    getInvestorSubTypes = async () => {
        const taxoFields = prepareSelectParam(['name', 'id']);
        const maxLimit = 300;
        const bulkOperations = [];
        const allTypes = [];

        bulkOperations.push(axios.get(`${API_URL_NEW}/taxonomy-type/${TAXONOMIES.investorType}/taxonomy?fields=${taxoFields}&limit=${maxLimit}`));
        bulkOperations.push(axios.get(`${API_URL_NEW}/taxonomy-type/${TAXONOMIES.enablerType}/taxonomy?fields=${taxoFields}&limit=${maxLimit}`));
        bulkOperations.push(axios.get(`${API_URL_NEW}/taxonomy-type/${TAXONOMIES.corporateType}/taxonomy?fields=${taxoFields}&limit=${maxLimit}`));

        const response = await Promise.all(bulkOperations);

        if (response[0] && response[0].data.data && response[0].data.data.length > 0) {
            response[0].data.data.map(item => {
                allTypes.push({ ...item, entityType: 'investor' });
            });
        }

        if (response[1] && response[1].data.data && response[1].data.data.length > 0) {
            response[1].data.data.map(item => {
                allTypes.push({ ...item, entityType: 'enabler' });
            });
        }

        if (response[2] && response[2].data.data && response[2].data.data.length > 0) {
            response[2].data.data.map(item => {
                allTypes.push({ ...item, entityType: 'corporate' });
            });
        }

        this.setState({ investorSubTypes: allTypes });
    }

    getCities = async (countryId) => {
        const citiesArr = [];
        const cities = await taxonomyApi.getCities(countryId);
        cities.map(item => citiesArr.push({ label: item.name, value: item.id }));
        this.setState({ citiesArr });
    }

    checkOwner = (profileUrl) => {

        const path = this.entityType.toLowerCase();

        if (this.props.match.path.indexOf('claim') > -1) return;

        entityApi.getUserTeamStatus(this.id, path).then(
            response => {
                if (!isEmpty(response) && response.is_team_member !== 1) {
                    window.location = `${WEB_URL}/${profileUrl}`;
                }
            }
        );
    }

    choseTeamMember = (event) => {
        for (let i = 0; i < this.state.preload.length; i++) {
            // if (parseInt(this.state.preload[i].uid, 10) === parseInt(event, 10)) {
            if (parseInt(this.state.preload[i].id, 10) === parseInt(event, 10)) {

                const firstName = this.state.preload[i].first_name ? this.state.preload[i].first_name : "";
                const lastName = this.state.preload[i].last_name ? this.state.preload[i].last_name : "";
                const value = `${firstName} ${lastName}`;
                this.setState({
                    tempName: value,
                    tempFile: this.state.preload[i].profile_pic_data,
                    tempUid: this.state.preload[i].id,
                    tempCode: this.state.preload[i].username,
                    preload: []
                });
                break;
            }
        }
    }

    choseUpdateTeamMember = (event) => {
        for (let i = 0; i < this.state.preload.length; i++) {
            if (parseInt(this.state.preload[i].id, 10) === parseInt(event, 10)) {
                const firstName = this.state.preload[i].first_name ? this.state.preload[i].first_name : "";
                const lastName = this.state.preload[i].last_name ? this.state.preload[i].last_name : "";
                const value = `${firstName} ${lastName}`;

                this.setState({
                    tempName2: value,
                    tempFile2: this.state.preload[i].profile_pic_data,
                    tempUid2: this.state.preload[i].id,
                    tempCode2: this.state.preload[i].username,
                    preload: []
                });
                break;
            }
        }
    }

    choseStartups = (event) => {
        let thisObj = this;
        let list = thisObj.state.startupPreload;
        if (list.length < 1) {
            list = thisObj.state.startupPreloadList;
        }

        if (isObject(event)) {
            thisObj.setState({
                startup: event,
                tempInvestedIn: event.id,
                tempInvestedInNid: event.id,
                startupId: event.id,
                startupPreload: [],
                startupIdTemp: event.id,
                startupPreloadList: [],
                //
                investedin_id: event.id,
                investedin_type: event.type,
                investedin_name: event.title,
                isShowStartupInvitation: false
            });
        } else {
            for (let i = 0; i < list.length; i++) {
                if (parseInt(list[i].id, 10) === parseInt(event, 10)) {
                    let value = list[i].name;
                    let nid = list[i].id;

                    thisObj.setState({
                        startup: value,
                        tempInvestedIn: value,
                        tempInvestedInNid: nid,
                        startupId: event,
                        startupPreload: [],
                        startupIdTemp: nid,
                        startupPreloadList: [],
                        //
                        investedin_id: nid,
                        investedin_type: list[i].type,
                        investedin_name: value,
                        isShowStartupInvitation: false
                    });
                    break;
                }
            }
        }
    };

    choseSingleStartup = (event) => {
        let thisObj = this;

        for (let i = 0; i < thisObj.state.startupPreload.length; i++) {
            if (parseInt(thisObj.state.startupPreload[i].id, 10) === parseInt(event, 10)) {
                let value = thisObj.state.startupPreload[i].name;
                let object = this.state.startups;
                object[object.length] = { name: value, id: thisObj.state.startupPreload[i].id };
                thisObj.setState({
                    startups: object,
                    startupPreload: []
                });
                break;
            }
        }
    };

    choseSingleStartupEdit = (event) => {
        let thisObj = this;

        for (let i = 0; i < thisObj.state.startupPreload.length; i++) {
            if (parseInt(thisObj.state.startupPreload[i].id, 10) === parseInt(event, 10)) {
                let value = thisObj.state.startupPreload[i].name;
                let object = this.state.startups;
                object[object.length] = { name: value, itemId: thisObj.state.startupPreload[i].id, action: 'add' };
                thisObj.setState({
                    startups: object,
                    startupPreload: []
                });
                break;
            }
        }
    };

    choseInst = (data) => {
        let { institutionArr, editParams } = this.state;


        institutionArr.push({
            linked_id: data.id,
            linked_type: data.type,
            name: data.title
        });

        this.setState({
            institutionArr: institutionArr,
            institution: data.title,
            institutions: [],
        });
    }

    onEditTeamMember = (index) => {
        this.setState({
            editTeamMember: index,
            tempId2: this.state.team[index].id,
            tempName2: this.state.team[index].name,
            tempRole2: this.state.team[index].role,
            tempFile2: this.state.team[index].logo,
            tempUid2: this.state.team[index].uid,
            tempCode2: this.state.team[index].username,
            tempAction2: this.state.team[index].action,
            teamNameEdit: this.state.team[index].name,
            // invalidname2: false,
            // isEmptyTeamName2: false,
        })
    };

    deleteTeamMember = (index) => {
        this.setState({
            deleteTeamMember: index
        })
    };

    closeSuggestions() {
        this.setState({
            preload: [],
            namePreload: [],
            startupPreload: []
        });
    }

    scrollToErrorRef = () => {
        if (this.errorRef.offsetParent && this.errorRef.offsetParent.offsetTop) {
            window.scrollTo(0, this.errorRef.offsetParent.offsetTop)
        }
    }

    close(event) {
        if (this.state.isSaving) return;
        this.setState({
            golive: false,
            editTeamMember: false,
            deleteTeamMember: false,
            editRound: false,
            deleteRound: false,
            editInvestor: false,
            editInvestor2: false,
            deleteInvestor: false,
            deleteInvestor2: false,
            addInvestor: false,


            tempRoundDate: moment(),
            tempRoundRaised: '',
            tempRoundCurr: '',
            tempRoundType: '',
            tempInvestedIn: '',
            tempRoundInvested: '',
            tempRoundRound: '',

            tempInvestor: '',
            tempInvestorRaised: '',
            investorEntityType: '',

            agree: false,
            isSaving: false,
            appliedSuccess: false,
        });

        document.body.style.overflowY = 'scroll';
    }

    validateUrls() {
        const { isfacebookUrlValid, istwitterUrlValid, islinkedinUrlValid, website, linkedin, facebook, twitter } = this.state;

        /**
        * If website url doesn't have https:// then append if
        */
        if (website) {
            this.setState({
                website: `https://${website}`,
            });
        }

        /**
        * If social urls are not valid
        * Show and error and don't let the other step proceed
        */
        if ((!isfacebookUrlValid && facebook) || (!islinkedinUrlValid && linkedin) || (!istwitterUrlValid && twitter)) {
            this.setState({
                urlError: true,
            });
            return false;
        } else {
            this.setState({
                urlError: false,
            });
            return true;
        }
    }

    beforeSave() {
        this.setState({
            golive: true,
        })
    }

    agree() {
        if (this.state.agree === false) {
            this.setState({
                agree: true
            })
        } else {
            this.setState({
                agree: false
            })
        }
    }

    preview() {
        this.saveNode(0);
    }

    validateFile = (event, type = 'file') => {
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();
        const fileSize = file.size;
        const fiveMbs = 5242880;

        // @reference https://www.computerhope.com/jargon/f/file-format.htm
        const allowedImageTypes = ['jpeg', 'jpg', 'png', 'gif']
        const allowedTypes = ['jpeg', 'jpg', 'png', 'gif', 'doc', 'docx', 'pdf', 'ppt', 'pptx']

        let allowedFileTypes
        if (type === 'image') {
            allowedFileTypes = allowedImageTypes
        } else {
            allowedFileTypes = allowedTypes
        }

        if (fileSize > fiveMbs) {
            this.setState({ popupErrorMsg: 'Maximum file size is 5MB', });
            return false;

        } else if (allowedFileTypes.indexOf(extension.toLowerCase()) === -1) {
            this.setState({ popupErrorMsg: 'File type is not supported', });
            return false;

        } else {
            return file;
        }
    }

    onDrop(event) {
        return new Promise((resolve, reject) => {
            let file = event.target.files[0];

            if (file !== undefined && file !== null) {
                let fileName = file.name.toLowerCase();
                let filesize = file.size;
                let object = {};

                if (file.size > 5242880) {
                    object[event.target.name + 'Error'] = 'Maximum size 5MB';
                } else if (fileName.indexOf('.jpg') === -1 &&
                    fileName.indexOf('.gif') === -1 &&
                    fileName.indexOf('.png') === -1 &&
                    fileName.indexOf('.jpeg') === -1) {
                    object[event.target.name + 'Error'] = "Support only '.jpg', '.gif', '.png', '.jpeg' formats";
                } else {
                    object[event.target.name + 'File'] = fileName;
                    object[event.target.name + 'Size'] = filesize;
                    object[event.target.name] = file;
                    object[event.target.name + 'Error'] = null;

                    resolve(file);
                }

                this.setState(object);
                reject({})
            } else {
                reject({})
            }
        })
    }

    onDropEdit = (event, code, msg) => {
        const { editParams } = this.state;
        let file = event.target.files[0];

        if (file !== undefined && file !== null) {
            let fileName = file.name.toLowerCase();
            let filesize = file.size;
            let object = {};

            if (file.size > 5242880) {
                object[event.target.name + 'Error'] = 'Maximum size 5MB';
            } else if (fileName.indexOf('.jpg') === -1 &&
                fileName.indexOf('.gif') === -1 &&
                fileName.indexOf('.png') === -1 &&
                fileName.indexOf('.jpeg') === -1) {
                object[event.target.name + 'Error'] = "Support only '.jpg', '.gif', '.png', '.jpeg' formats";
            } else {
                object[event.target.name + 'File'] = fileName;
                object[event.target.name + 'Size'] = filesize;
                object[event.target.name] = file;
                object[event.target.name + 'Error'] = null;
                editParams[event.target.name] = file;
            }

            if (code && msg) {
                this.handleErrorChanges(code, msg);
            }

            this.setState(object);
            this.setState({ editParams: editParams });
            return file;
        } else {
            return {};
        }
    }

    publish(e) {
        if (this.state.agree) {
            this.setState({ errorPopup: '', golive: false })

            this.saveNode(1)
        } else {
            this.setState({
                errorPopup: 'Please complete the T&C box to proceed'
            })
        }
    }

    handleEditChange = (event, code, msg) => {
        const { className, name, value } = event.target;
        const editParams = { ...this.state.editParams };
        let obj = {};
        const multName = name + 'Arr';

        const index = event.nativeEvent.target.selectedIndex;
        let label;
        if (index) {
            label = event.nativeEvent.target[index].text;
        }

        obj[name] = value;
        if (label && label !== '- Please Select -') {

            if (className.indexOf('mult') !== -1) {

                let multVal = this.state[multName];
                if (Array.isArray(multVal)) {
                    let ind = multVal.findIndex(item => {
                        return item.itemId === parseInt(value) && item.name === label
                    })
                    if (ind === -1) {
                        multVal.push({
                            itemId: parseInt(value, 10),
                            name: label
                        });

                        obj[multName] = multVal;
                    }
                }
            }
            else {
                editParams[name] = value;
            }
        } else {
            editParams[name] = value;
        }

        if (value && code && msg) {
            this.handleErrorChanges(code, msg);
        }

        this.setState(obj);
        this.setState({ editParams: editParams });
    }

    handleTicketSizeInput(ev, str1, str2) {
        if (ev.target.value > 0 && ev.target.value < 1000 || ev.target.value === '') {
            let obj = {}
            let ticketsize
            let ticketLabel = this.state[`${str1}_ticket`]

            if (ticketLabel === 'none') {
                ticketsize = ev.target.value
            }
            else if (ticketLabel === 'Thousand') {
                ticketsize = ev.target.value.toString() + '000'

            }
            else if (ticketLabel === 'Million') {
                ticketsize = ev.target.value.toString() + '000000'
            }

            obj[`${str1}_ticket_value`] = ev.target.value
            obj[`${str1}_ticket_value_final`] = parseInt(ticketsize)
            this.setState(obj)

            if (str2 === 'edit') {
                let { editParams } = this.state
                editParams[`${str1}_ticket_value_final`] = parseInt(ticketsize)
            }
        }
    }

    handleTicketSizeSelect(ev, str1, str2) {
        let ticketsize
        let obj = {}
        if (ev.target.value === 'none') {
            ticketsize = this.state[`${str1}_ticket_value`]
        }
        else if (ev.target.value === 'Thousand') {
            ticketsize = this.state[`${str1}_ticket_value`].toString() + '000'

        }
        else if (ev.target.value === 'Million') {
            ticketsize = this.state[`${str1}_ticket_value`].toString() + '000000'
        }
        obj[`${str1}_ticket`] = ev.target.value
        this.state[`${str1}_ticket_value_final`] = parseInt(ticketsize)
        this.setState(obj)

        if (str2 === 'edit') {
            let { editParams } = this.state
            editParams[`${str1}_ticket_value_final`] = parseInt(ticketsize)
        }
    }

    // handleChange event for entity name
    handleNameChange = (event, code, msg) => {

        const elem = event.target.className;
        const { value } = event.target;

        let invalid_name_err;
        let invalid_name_msg = "";
        this.setState({ name: value })

        if (value && value.length > 0) {
            // check for invalid names (spam urls, etc.)
            if (stringContainsURLs(value)) {
                invalid_name_err = true;
                invalid_name_msg = <>This company name contains invalid characters, please enter a valid company name</>;
            }
            // check for exising names
            else {
                const entityType = elem.split("Check")[0]; // get entity name
                const entityCode = generateEntityCode(value);
                const filters = prepareFilterParam([
                    { key: "code", value: [entityCode], op: ALLOWED_QUERY_OPERATOR.equal }
                ]);

                const URL = `${API_URL_NEW}/${entityType}?fields=id^,code^,name^,owned_by^,is_deleted&filters=${filters}&limit=10`;
                axios.get(URL).then(response => {
                    if (response.data.status === 200 && response.data.data.length > 0) {

                        const check = response.data.data[0];

                        if (!isEmpty(check) && check.owned_by > 0 && check.is_deleted === 1) {
                            invalid_name_err = true;
                            invalid_name_msg = <>This {entityType} was removed from MAGNiTT by one of their team members. To reinstate it, please <u><a href={`mailto:support@magnitt.com?subject=Reinstate my ${entityType} - ${check.name}`} style={{ fontSize: 14 }}>contact us</a></u></>;
                        }

                        else if (!isEmpty(check) && check.owned_by > 0) {
                            invalid_name_err = true;
                            invalid_name_msg = <>This {entityType} profile has already been created and claimed</>;
                        }

                        else if (!isEmpty(check) && check.owned_by === 0) {
                            const URL = getItemWebUrl(entityType + "s", check.id, check.code);
                            invalid_name_err = true;
                            invalid_name_msg = <>This {entityType} profile has already been created by MAGNiTT's team, you can <u><a href={`${WEB_URL}/${URL}/claim`} style={{ fontSize: 14 }}>claim it here</a></u></>;
                        }

                        this.setState({
                            invalid_name_err,
                            invalid_name_msg
                        })

                    }
                });
            }
        }

        if (value && msg && code && !invalid_name_err) {
            this.handleErrorChanges(code, msg);
        }

        this.setState({
            invalid_name_err,
            invalid_name_msg
        })
    }

    handleChange(event, code, msg) {
        let thisObj = this;
        let obj = {};
        let elem = event.target.className;
        let name = event.target.name;
        let value = event.target.value;
        let actualName = event.target.name;
        let actualValue = event.target.value;

        if (elem.indexOf('mult') !== -1) {
            name += 'Arr';
            let state = { ...this.state };
            state = state[name];

            if (state.indexOf(value) === -1 && value !== '' && value !== 'multi') {
                state.push(value);
            }
            value = state;
        }

        if (elem.indexOf('pre') !== -1) {
            this.pre(name, value);
        }

        if (elem === 'applicationStartup' || elem === 'applicationStartup error') {
            this.loadStartupData(value)
        }

        obj[name] = value;
        obj[actualName] = actualValue;

        if (value && msg && code) {
            this.handleErrorChanges(code, msg);
        }

        obj.hasUnsavedChanges = true; // for forms where we alert users of unsaved changes

        this.setState(obj);
    }

    handleChangeRoundDate(date) {
        this.setState({
            tempRoundDate: date
        });
    }

    handleEditSupport = index => {
        let { supportData, supportArr } = this.state;
        supportData[index].isSelected = !supportData[index].isSelected;
        const supportIndex = findIndex(supportArr, { itemId: supportData[index].id });

        // if (supportData[index].isSelected) {
        if (supportIndex > -1) {
            if (supportArr[supportIndex].isDeleted) {
                // supportArr.push({ itemId: supportData[index].id });
                supportArr[supportIndex].isDeleted = false;
            } else {
                supportArr[supportIndex].isDeleted = true;
            }
        } else {
            // const supportIndex = findIndex(supportArr, { itemId: supportData[index].id });
            // supportArr[supportIndex].isDeleted = true;
            supportArr.push({ itemId: supportData[index].id, name: supportData[index].name });
        }

        this.setState({ supportData, supportArr });
    }

    handleSupport = index => {
        let { supportData, supportArr } = this.state;

        supportData[index].isSelected = !supportData[index].isSelected;

        if (supportData[index].isSelected) {
            supportArr.push(supportData[index].id);
        } else {
            supportArr = supportArr.filter(v => {
                return v !== supportData[index].id
            });
        }

        this.setState({ supportData, supportArr })
    }

    async pre(name, value) {

        let thisObj = this;
        const userFields = prepareSelectParam(['id', 'first_name', 'last_name', 'code']);

        if (name === 'startup' || name === 'tempInvestedIn' || name === 'startupTemp' || name === 'investedin_name') {
            if (value !== '') {
                let isShowStartupInvitation = false;

                thisObj.setState({
                    investedin_id: null,
                    isSearchingStartups: true
                })

                const startupFields = prepareSelectParam(['id', 'name', 'entity_type']);
                axios.get(`${API_URL_NEW}/startup/?fields=${startupFields}&filters=name^sub:${value}&limit=10`)
                    .then(function (response) {

                        let resp = response.data.data;

                        // used to select startup for funding round
                        if (name === 'investedin_name') {
                            if (resp.length === 0) {
                                isShowStartupInvitation = true;
                            }

                            thisObj.setState({ startupPreloadList: resp, isShowStartupInvitation });
                        }
                        // used to select startup for startup relations
                        else {
                            thisObj.setState({ startupPreload: resp });
                        }

                        thisObj.setState({ isSearchingStartups: false })

                    });
            } else {
                thisObj.setState({
                    startupPreload: [],
                    startupPreloadList: []
                });
            }
        } else if (name === 'tempInvestor') {
            if (value !== '') {
                const tempObj = {};
                let isShowInvestorInvitation = false;
                let investorsPreload = [];
                const investorInvitations = this.state.investorInvitations;

                if (this.state.investorEntityType === 'ventureCapital') {
                    const encodedValue = encodeURIComponent(value);
                    axios.get(`${API_URL_NEW}/search/entity-investors/?keyword=${encodedValue}`)
                        .then(response => {
                            investorsPreload = response.data.data;

                            // Search in the array of already invited investors
                            if (investorInvitations && Array.isArray(investorInvitations)) {
                                const filteredInvestorInviations = investorInvitations.filter(v => v.title.indexOf(value) > -1);
                                investorsPreload = investorsPreload.concat(filteredInvestorInviations);
                            }

                            if (investorsPreload.length < 1) {
                                isShowInvestorInvitation = true;
                            }

                            this.setState({ investorsPreload, isShowInvestorInvitation });
                        });

                    // If angelInvestor, search in user
                } else if (this.state.investorEntityType === 'angelInvestor') {
                    axios.get(`${API_URL_NEW}/user/?fields=${userFields}&filters=keyword^:${value}`)
                        .then(response => {
                            // let tempInvestors = [];
                            response.data.data.map(item => investorsPreload.push({
                                id: item.id,
                                title: `${item.first_name} ${item.last_name}`,
                                type: 'USER',
                                code: item.code
                            }))

                            this.setState({ investorsPreload });
                        });
                }

            } else {
                thisObj.setState({
                    investorsPreload: []
                });
            }
        } else if (name === 'institution') {
            if (value !== '' && value.length > 1) {
                // axios.get(`${API_URL_NEW}/investor/?fields=${invFields}&id,name&filters=name^sub:${value}`)
                const encodedValue = encodeURIComponent(value);
                axios.get(`${API_URL_NEW}/search/entity-investors/?keyword=${encodedValue}`)
                    .then(function (response) {
                        thisObj.setState({
                            institutions: response.data.data
                        });
                    });
            } else {
                thisObj.setState({
                    institutions: []
                });
            }
        } else {

            if (value !== '') {
                let isShowTeamInvitation = false;
                let usersPayload = [];
                const userFields = prepareSelectParam(['id', 'first_name', 'last_name', 'profile_pic_data', 'username'])

                axios.get(`${API_URL_NEW}/user/?fields=${userFields}&filters=keyword^:${value}^,status^:1^;0&limit=10`)
                    .then(response => {
                        usersPayload = response.data.data;
                        if (usersPayload.length < 1) {
                            isShowTeamInvitation = true;
                        }

                        this.setState({
                            preload: usersPayload,
                            isShowTeamInvitation
                        });
                    });
            } else {
                thisObj.setState({
                    preload: [],
                    isShowTeamInvitation: false
                });
            }
        }
    }

    validationFunction() {
        let error_count = 0
        //let error = []
        if (this.state['err'][parseInt(this.state.activeBlock, 10)]) {
            let fields = this.state['err'][parseInt(this.state.activeBlock, 10)].fields;
            fields.map((item, i) => {
                let ind = this.state.errorMessages.indexOf(item.msg)
                if (item.name !== 'logo') {
                    if (this.state[item.name] === '' || this.state[item.name] === undefined || this.state[item.name] === null ||
                        (this.state[item.name] === 'multi' && this.state[`${item.name}Arr`].length === 0)) {
                        this.state[`${item.name}_error`] = true
                        error_count = error_count + 1
                        if (ind === -1) {
                            if (item.msg) {
                                this.state.errorMessages.push(item.msg)
                            } else {
                                // handle pages where item.msg is not set (BuyMagnitt)
                                this.state.errorMessages.push(item.name)
                            }
                        } else {
                            // console.log(ind + " " + item.name + " " + this.state[item.name])
                        }
                    }
                    else {
                        this.state[`${item.name}_error`] = false
                        if (ind > -1)
                            this.state.errorMessages.splice(ind, 1)
                    }
                }
                else {
                    if (this.state['logoFile'] === null || this.state['logoFile'] === undefined || this.state['logoFile'].length === 0) {
                        this.state[`${item.name}_error`] = true
                        error_count = error_count + 1
                        if (ind === -1)
                            this.state.errorMessages.push(item.msg)
                    }
                    else {
                        this.state[`${item.name}_error`] = false
                        if (ind > -1)
                            this.state.errorMessages.splice(ind, 1)
                    }
                }
            })
            this.setState(this.state)
        }

        if (error_count > 0) {
            // console.log("errors = " + error_count)
            // console.log("errorMessages = " + this.state.errorMessages)
            // console.log(this.state.errorMessages.length)
            return false
        } else {
            // true = passed validation
            this.setState({ errorMessages: [] })
            return true;
        }

    }

    validateSocialUrl() {
        let err_count = 0
        this.state.urlerr.map(item => {
            if (this.state[item.name] !== '' && this.state[item.name] !== null) {
                if (this.state[item.name].indexOf(`${item.name}.com/`) === -1 || this.state[item.name].indexOf(`${item.name}.com`) === -1) {
                    this.state[`is${item.name}UrlValid`] = false
                    err_count = err_count + 1
                }
                else {
                    this.state[`is${item.name}UrlValid`] = true
                }
            }
            else {
                this.state[`is${item.name}UrlValid`] = true
            }
        })
        this.setState(this.state)
        if (err_count > 0) {
            this.state.urlError = true
            return false
        }
        else {
            this.state.urlError = false
            return true
        }
    }

    validateWebsiteUrl() {
        const input = this.state.website
        //this.setState({ website: input })
        if (input !== '' && input != null) {
            if (input.match(/(https?:\/\/)?([\w\d]+\.)?[\w\d]+\.\w+\/?.+/)) {
                this.setState({ isWebsiteUrlValid: true })
                return true
            } else {
                this.setState({ isWebsiteUrlValid: false })
                return false
            }
        }
        else {
            return true
        }
    }

    validateVideoUrl() {
        const input = this.state.videolink

        if (input !== '' && input != null) {
            if (input.match(/(https?:\/\/)?([\w\d]+\.)?[\w\d]+\.\w+\/?.+/)) {
                this.setState({ isVideoUrlValid: true })
                return true
            } else {
                this.setState({ isVideoUrlValid: false })
                return false
            }
        }
        else {
            return true
        }
    }

    validateAppUrl() {
        const { iosAppUrl, androidAppUrl } = this.state;

        let returnValue = true;
        let isIosUrlValid = true;
        let isAndroidUrlValid = true;

        // IOS link can be of itunes store of app store
        if (iosAppUrl && (!APP_STORE_LINK_REGEX.test(iosAppUrl) && !ITUNES_STORE_LINK_REGEX.test(iosAppUrl))) {
            isIosUrlValid = false;
            returnValue = false;
        }

        if (androidAppUrl && !PLAY_STORE_LINK_REGEX.test(androidAppUrl)) {
            isAndroidUrlValid = false;
            returnValue = false;
        }

        this.setState({ isIosUrlValid, isAndroidUrlValid });
        return returnValue;
    }

    validateTicketSize() {
        if (this.state.min_ticket_value_final > this.state.max_ticket_value_final) {
            this.setState({ ticketSizeError: true })
            return false
        }
        else {
            this.setState({ ticketSizeError: false })
            return true
        }
    }

    // Returns true only if the entity(startup, investor...) is unclaimed
    validateUnclaim() {
        const { check, isClaimClicked } = this.state;
        let isUnclaimed = true;

        if (!isClaimClicked) {
            if (check.length > 0 && check[0].nid > 0 && check[0].uid === 0) {
                isUnclaimed = false;
                this.setState({ unclaim_error: true })
            } else {
                this.setState({ unclaim_error: false });
            }
        }

        return isUnclaimed;
    }

    teamMemberSubmit() {
        if (!this.state.tempName || !this.state.tempRole) {
            return this.setState({ isTeamRequiredError: true, });
        }

        if (this.state.tempUid > 0) {
            let team = this.state.team;
            let obj = {};

            const index = team.findIndex(item => item.uid == this.state.tempUid);

            if (index == -1) {
                obj['name'] = this.state.tempName;
                obj['role'] = this.state.tempRole;
                obj['uid'] = this.state.tempUid;
                obj['logo'] = this.state.tempFile;
                obj['username'] = this.state.tempCode;
                obj['action'] = 'new';
                team.unshift(obj);
            }
            // if user's adding a team member who was prev deleted
            else if (team[index].action == "delete") {
                team[index].action = this.isAddPage ? "new" : "update";
                team[index].role = this.state.tempRole;
            }

            this.setState({ team: team, isTeamRequiredError: false, preload: [], tempUid: '', invalidname: false, tempName: '', tempRole: '', tempCode: '' })
        }
        else {
            this.setState({ invalidname: true })
        }
    }

    updateTeamMember() {
        if (!this.state.tempRole2) {
            return this.setState({ isUpdateTeamError: true, })
        }

        if (this.state.tempUid2 !== '') {
            let team = this.state.team;
            let obj = {};
            obj['id'] = this.state.tempId2;
            obj['name'] = this.state.tempName2;
            obj['role'] = this.state.tempRole2;
            obj['logo'] = this.state.tempFile2;
            obj['uid'] = this.state.tempUid2;
            obj['username'] = this.state.tempCode2;
            obj['action'] = this.state.tempAction2 ? this.state.tempAction2 : 'update';
            // obj['action'] = this.state.tempAction2;
            team[this.state.editTeamMember] = obj
            this.setState({ team: team, preload: [], tempName2: '', tempId2: '', tempRole2: '', tempFile2: '', tempUid: '', tempCode2: '', editTeamMember: false, isUpdateTeamError: false })
            document.body.style.overflowY = 'scroll';
        }
    }

    removeTeam = (event) => {
        let team = this.state.team;
        team[event].action = 'delete';

        this.setState({
            team: team,
            deleteTeamMember: false
        });
        document.body.style.overflowY = 'scroll';
    }

    renderTeamErrors = () => {
        let content = [];

        if (this.state.invalidname) {
            content.push(<div className="red mt-2">Please choose a valid Team member</div>);
        }

        if (this.state.isTeamRequiredError) {
            if (!this.state.tempName) {
                content.push(<div className="red mt-2">Please fill the name field</div>);
            }

            if (!this.state.tempRole) {
                content.push(<div className="red mt-2">Please select the role</div>);
            }
        }

        return content;
    }

    removeMult = (name, value) => {
        let state = { ...this.state }
        state = state[name];
        let str = name.substring(0, name.indexOf('Arr'))

        for (let i = 0; i < state.length; i++) {
            if (parseInt(state[i], 10) === parseInt(value, 10)) {
                state.splice(i, 1);
                if (state.length === 0) this.state[str] = ''
            }
        }

        let obj = {};
        obj[name] = state;

        this.setState(this.state);
    }

    /**
     * Prepare funding object for API call
     * Only take the required props and leave the rest
     * @param {Object} obj                  funding object which has to be cleaned
     * @param {Number} newlyCreatedEntityId Id of the newly created entity
     * @returns {Object}                    Funding object with investors array
     */
    prepFundingObj = (obj, newlyCreatedEntityId) => {
        let returnObj = {};
        returnObj.investors = [];

        if (obj.amount_raised) {
            returnObj.amount_raised = obj.amount_raised;
        }

        /*if (obj.pre_money_valuation) {
            returnObj.pre_money_valuation = obj.pre_money_valuation;
        }*/

        if (obj.post_money_valuation) {
            returnObj.post_money_valuation = obj.post_money_valuation;
        }

        if (obj.currency) {
            returnObj.currency = obj.currency;
        }

        if (!isEmpty(obj.investors)) {
            let tempObj;
            obj.investors.map(v => {
                tempObj = {};

                tempObj.entity_id = v.entity_id;
                tempObj.entity_type = v.entity_type;
                tempObj.invested_amount = v.invested_amount ? v.invested_amount : null;
                tempObj.is_convertible = v.is_convertible ? v.is_convertible : 0;

                returnObj.investors.push(tempObj);
            });
        }

        returnObj.entity_id = obj.entity_id;
        returnObj.entity_type = obj.entity_type;
        returnObj.funding_stage = obj.funding_stage;
        returnObj.date_raised = obj.date_raised;

        // Add current investor to the investors
        returnObj.investors.push({
            entity_id: newlyCreatedEntityId,
            entity_type: this.entityType,
            invested_amount: obj.invested_amount == '' ? null : obj.invested_amount
        });

        return returnObj;
    }

    /**
     * This method will prepare funding APIs
     * There are two types of entities in each funding and in it's investors
     *  1. Where entity exists
     *  2. Where entity doesn't exist and invitation has to be sent the id
     *
     * For the first case, funding object would be simply pushed to the array
     * For the second case, id can be take in two ways
     *  1. Check in the redux state if it's already invited then take the id
     *  2. If not already invited then call the invite API and get the id
     *
     * Funding round startup has to be invited before investors invitations
     * Because investor invitation requires startup id which recieved funding
     *
     * @param {Number} id   id of the newly created entity
     * @returns {Array}     Array of funding APIs
     */
    prepFundingAPIs = (id) => {
        return new Promise(async (resolve, reject) => {

            let fundingObj = {};
            let bulkOperations = [];
            const fundingRounds = this.props.fundingRound.fundingRounds;
            const startupInvitations = this.props.fundingRound.startupInvitations;
            const investorInvitations = this.props.fundingRound.investorInvitations;

            try {

                for (let i = 0; i < fundingRounds.length; i++) {
                    const v = fundingRounds[i];
                    let startupInviteRes, SICEinviteRes;

                    fundingObj = { ...v };

                    if (has(v, 'status') && v.status === JOIN_FUNDING_ROUND_STATUS.pending) {
                        const payload = {
                            currency: v.currency,
                            funding_id: v.round_id,
                            invested_amount: v.invested_amount,
                            is_convertible: v.isConvertible ? 1 : 0,
                            status: v.status,
                        }

                        bulkOperations.push(this.handlePost(`${this.entityType}/${id}/funding/${v.round_id}/invested`, payload));
                    } else {


                        if (v.isInvitation) {
                            const inviteIndex = findIndex(startupInvitations, { name: v.startupName });

                            // If startup has already been invited then take it's id, no need to call the API again
                            if (inviteIndex > -1 && startupInvitations[inviteIndex].id) {
                                fundingObj.entity_id = startupInvitations[inviteIndex].id;
                                v.entity_id = startupInvitations[inviteIndex].id;

                            } else {
                                const hq_id = startupInvitations[inviteIndex].hq_id;
                                const industry_id = startupInvitations[inviteIndex].industry_id;

                                const body = {
                                    name: v.startupName,
                                    email_relations: v.startupEmailRelations,
                                    entity_id: id,
                                    entity_type: this.entityType,
                                    hq_id: hq_id,
                                    industry_id: industry_id
                                };

                                startupInviteRes = await this.handlePost(`startup/invite`, body);

                                if (isEmpty(startupInviteRes.data)) {
                                    throw "Inviting startup failed"
                                }
                                const tempObj = startupInvitations[inviteIndex];
                                fundingObj.entity_id = startupInviteRes.data.data.id;
                                v.entity_id = startupInviteRes.data.data.id;
                                tempObj.id = startupInviteRes.data.data.id;

                                // Update the id of the startup inviation
                                this.props.fundingRoundActions.updateStartupInvitation(tempObj, inviteIndex);
                            }
                        }

                        // Check if any of the invetors has invitation
                        if (v.investors.filter(v => v.isInvitation).length > 0) {
                            fundingObj.investors = [];

                            for (let j = 0; j < v.investors.length; j++) {
                                const investItem = v.investors[j];

                                if (investItem.isInvitation) {

                                    const inviteIndex = findIndex(investorInvitations, { name: investItem.name });

                                    // If investor has already been invited then take it's id, no need to call the API again
                                    if (inviteIndex > -1 && investorInvitations[inviteIndex].id) {
                                        investItem.entity_id = investorInvitations[inviteIndex].id;

                                    } else if (inviteIndex > -1) {
                                        const body = {
                                            name: investItem.name,
                                            email_relations: investItem.emailRelations,
                                            entity_id: v.entity_id,
                                            entity_type: v.entity_type,
                                            investor_type: this.entityType,
                                            investor_id: id
                                        };

                                        SICEinviteRes = await this.handlePost(`${investItem.entity_type.toLowerCase()}/invite`, body);

                                        if (isEmpty(SICEinviteRes.data)) {
                                            throw "Inviting entity failed"
                                        }

                                        const tempObj = investorInvitations[inviteIndex];
                                        investItem.entity_id = SICEinviteRes.data.data.id;
                                        tempObj.id = SICEinviteRes.data.data.id;

                                        // Update the id of the startup inviation
                                        this.props.fundingRoundActions.updateInvestorInvitation(tempObj, inviteIndex);
                                    }
                                }

                                fundingObj.investors.push(investItem);
                            }
                        }

                        const finalBody = this.prepFundingObj(fundingObj, id);
                        bulkOperations.push(this.handlePost(`${this.entityType}/${id}/funding`, finalBody));
                    }
                }

                resolve(bulkOperations);
            } catch (err) {
                reject(err);
            }
        })
    }

    /**
     * Assign entity_ids to the funding rounds, where entity_id is from invited startup
     * @param {Array} fundings          Array of funding along with investors array with each object
     * @param {Array} inviteResponses   API responses after the startups invitations has been created
     * @param {Number} entity_id        Id of currently created entity
     */
    prepInviteFundingAPI = async (fundings, inviteResponses, entity_id) => {
        let bulkOperations = [];
        let index;

        inviteResponses.map(v => {
            if (v.data.data && v.data.data.id) {
                index = findIndex(fundings, { startupName: v.data.data.name });
            }

            if (index > -1) {
                fundings[index].entity_id = v.data.data.id;

                bulkOperations.push(this.handlePost(`investor/${entity_id}/funding`, fundings[index]));
            }
        })

        return bulkOperations;
    }

    /**
      * Prepare data for funding from investors API (maybe invested API)
      * @returns {Array} Funding data for API
    */
    prepFundingFields = (id, entity_type) => {
        const { rounds } = this.state;
        let arReturn = [];
        let tempObj = {};
        if (rounds.length > 0) {
            rounds.map(v => {
                // Set basic details
                //TODO change from hardcoded invested in type
                tempObj = {
                    entity_id: v.investedin_id,
                    entity_type: 'STARTUP',
                    date_raised: v.date,
                    funding_stage: v.stage,
                    investors: [{
                        entity_id: id,
                        entity_type: entity_type,
                    }],
                };

                if (v.total_invested) {
                    tempObj.amount_raised = v.total_invested;
                }

                if (v.curr) {
                    tempObj.currency = v.currency;
                }

                if (v.invested_amount) {
                    tempObj.investors[0].invested_amount = v.invested_amount
                }

                arReturn.push(tempObj);
            });
        }
        return arReturn;
    }

    /**
     * Prepare data for related Startups API
     * @returns {Array} Related Startups data for API
    */
    prepRelStartupFields = (id) => {
        const { startups } = this.state;
        const invitedStartups = this.props.fundingRound.startupInvitations;
        let arReturn = [];
        let tempObj = {};
        if (startups.length > 0) {
            startups.map(v => {
                // Set basic details
                if (v && v.id) {
                    tempObj = {
                        startup_id: v.id
                    };
                } else {
                    let startup = findIndex(invitedStartups, { name: v.name })
                    tempObj = {
                        startup_id: invitedStartups[startup].id
                    };
                }
                arReturn.push(tempObj);
            });
        }

        return arReturn;
    }

    invRoundSubmit(event) {
        let { tempRoundInvested, tempRoundRound, rounds, tempInvestedInNid, startupPreload } = this.state;

        if ((startupPreload.length === 1 || tempInvestedInNid > 0) && this.state.tempRoundType > 0) {
            // Replace commas from string and parse it as a Number
            if (tempRoundInvested) {
                tempRoundInvested = tempRoundInvested.replace(/,/g, "");
                tempRoundInvested = parseInt(tempRoundInvested, 10);
            }

            if (tempRoundRound) {
                tempRoundRound = tempRoundRound.replace(/,/g, "");
                tempRoundRound = parseInt(tempRoundRound, 10);
            }

            if (tempInvestedInNid < 1) {
                tempInvestedInNid = startupPreload[0].id;
            }

            let obj = {};
            obj['date'] = moment(this.state.tempRoundDate).format().substr(0, 10);
            obj['investedin'] = this.state.tempInvestedIn;
            obj['investedin_id'] = tempInvestedInNid;
            obj['invested_amount'] = tempRoundInvested;
            obj['total_invested'] = tempRoundRound;
            obj['curr'] = this.state.tempRoundCurr;
            obj['stage'] = this.state.tempRoundType;
            obj['action'] = "new";
            rounds[rounds.length] = obj

            this.addStartupRel(tempInvestedInNid, this.state.tempInvestedIn);

            this.setState({
                rounds,
                tempInvestedIn: '',
                tempInvestedInNid: 0,
                tempRoundInvested: '',
                tempRoundRound: '',
                tempRoundCurr: '',
                tempRoundType: '',
                addround_error: false,
                startupPreload: []
            });
        }
        else {
            this.setState({ addround_error: true })
        }
    }

    invEditRound(index) {
        this.setState({
            editRound: index,
            tempRoundDate: moment(this.state.rounds[index].date),
            tempInvestedIn: this.state.rounds[index].investedin,
            tempRoundInvested: this.state.rounds[index].invested_amount,
            tempRoundRound: this.state.rounds[index].total_invested,
            tempRoundCurr: this.state.rounds[index].curr,
            tempRoundType: this.state.rounds[index].stage,
        })
    }

    invRemoveRound = (event) => {
        let rounds = this.state.rounds;
        if (rounds && rounds.length > 1) {
            rounds.splice(event, 1);
        } else {
            rounds = [];
        }

        this.setState({
            rounds: rounds
        });
        this.close();
        document.body.style.overflowY = 'scroll';
    }

    removeEditMult = (name, value) => {
        let obj = {};
        let data = this.state[name];
        const index = findIndex(data, { itemId: value.itemId });

        if (index > -1) {
            data[index].isDeleted = true;
            obj[name] = data;
            obj.hasUnsavedChanges = true; // for forms where we alert users of unsaved changes
            this.setState(obj);
        }
    }

    removeInstMult = (index, isEdit) => {
        let { institutionArr } = this.state;

        if (isEdit) {
            institutionArr[index].isDeleted = true;
        } else {
            institutionArr.splice(index, 1);
        }

        this.setState({ institutionArr });
    }

    // Prepare Add, update and delete APIs for team
    prepEditTeamAPIs = (userId = "", userTeamMemberId = "") => {
        const { team } = this.state;
        const entityType = this.entityType.toLowerCase();
        let tempObj = {};
        let APICalls = [];

        if (team.length > 0) {
            team.map(v => {
                tempObj = {
                    role_id: TEAM_EDIT_ROLE_ID,
                    user_id: v.uid,
                    team_role_id: v.role
                };

                if (v.uid == userId && userTeamMemberId != "" && v.action === "new") {
                    APICalls.push(this.handlePatch(`${entityType}/${this.id}/team/${userTeamMemberId}`, tempObj));
                } else if (v.action === "new") {
                    APICalls.push(this.handlePost(`${entityType}/${this.id}/team`, [tempObj]));

                } else if (v.id && v.action === "update") {
                    APICalls.push(this.handlePatch(`${entityType}/${this.id}/team/${v.id}`, tempObj));

                } else if (v.id && v.action === "delete") {
                    APICalls.push(this.handleDelete(`${entityType}/${this.id}/team/${v.id}`));
                }

                tempObj = {};
            });
        }

        return APICalls;
    }

    /**
     * @param {Array, Object}   data        Data which has to added
     * @param {String}          dataProp    Data prop which API requires e.g. industry_id, location_id
     * @param {String}          fieldType   Field type e.g. industry, geo-focus etc.
     * @returns {Array}                     API calls for either add or delete
     */
    prepEditRelAPIs = (data, dataProp, fieldType) => {
        const entityType = this.entityType.toLowerCase();
        let tempObj = {};
        let APICalls = [];

        if (data.length > 0) {
            data.map(v => {
                tempObj[dataProp] = v.itemId;

                if (v.hasOwnProperty('is_primary')) {
                    tempObj['is_primary'] = v.is_primary
                }

                if (!v.id && !v.isDeleted) {
                    APICalls.push(this.handlePost(`${entityType}/${this.id}/${fieldType}`, [tempObj]));

                } else if (v.id && v.isDeleted) {
                    APICalls.push(this.handleDelete(`${entityType}/${this.id}/${fieldType}/${v.id}`));
                }

                tempObj = {};
            });
        }

        return APICalls;
    }

    /**
     * @param {Array, Object}   data        Data which has to added
     * @returns {Array}                     API calls for either add or delete
     */
    prepEditIntAPIs = (data) => {
        const entityType = this.entityType.toLowerCase();
        let tempObj = {};
        let APICalls = [];

        if (data.length > 0) {
            data.map(v => {
                tempObj.linked_id = v.linked_id;
                tempObj.linked_type = v.linked_type;

                if (!v.isExisting && !v.isDeleted) {
                    APICalls.push(this.handlePost(`${entityType}/${this.id}/institution`, [tempObj]));

                } else if (v.isExisting && v.isDeleted) {
                    APICalls.push(this.handleDelete(`${entityType}/${this.id}/institution/${v.linked_id}/linked-type/${v.linked_type.toLowerCase()}`));
                }

                tempObj = {};
            });
        }

        return APICalls;
    }

    // Prepare related startup APIs based on the actions
    prepRelStartupAPIs = (id, entityType) => {
        const { startups } = this.state;
        let objStartup = {};
        let arrObjStartups = [];
        let APICalls = [];

        startups.map(v => {
            objStartup = {
                startup_id: v.itemId
            };

            if (v.action === "add") {
                arrObjStartups.push(objStartup);
            } else if (v.relId && v.action === "delete") {
                APICalls.push(this.handleDelete(`${this.entityType}/${this.id}/startup-rel/${v.relId}`));
            }

            objStartup = {};
        });
        if (arrObjStartups.length > 0) {
            APICalls.push(this.handlePost(`${this.entityType}/${this.id}/startup-rel`, arrObjStartups));
        }
        return APICalls;
    }

    addStartupRel = (id, name) => {
        const { startups } = this.state;

        const index = findIndex(startups, { id });

        if (index === -1) {
            startups.push({ id, name });
            this.setState({ startups });
        }
    }

    /* @so: begin Funding Round Functions */

    closeFundingRoundPopup = () => {
        this.setState({ showFundingRound: false, isEditRound: false })
    }

    deleteRound = (index) => {
        this.setState({ deleteRound: index })
    }

    /* @so: end Funding Round Functions */


    handleHQChange = async (event, type = 'add', code, msg) => {
        if (type === 'edit') {
            // If the hq gets changed, remove the city as well
            const editParams = this.state.editParams;
            editParams['city'] = "";
            this.setState({ editParams, city: "" });
            this.handleEditChange(event);
        } else {
            this.setState({ city: "" });
            this.handleChange(event)
        }

        if (event.target.value && event.target.value.length > 0) {
            const citiesArr = [];
            const countryId = event.target.value;
            const cities = await taxonomyApi.getCities(countryId);

            cities.map(item => citiesArr.push({ label: item.name, value: item.id }));
            this.setState({ citiesArr });

            if (code && msg) {
                this.handleErrorChanges(code, msg);
            }
        }
    }

    loadCities = async (input, callback, countryId) => {
        const citiesArr = [];
        let filters = "";

        if (input) {
            filters = prepareFilterParam([{ key: 'name', value: [input], op: ALLOWED_QUERY_OPERATOR.substring }]);
        }

        const cities = await taxonomyApi.getCities(countryId, filters);
        cities.map(item => citiesArr.push({ label: item.name, value: item.id }));
        return callback(citiesArr);
    }

    mapToState = (stateObj) => {
        Object.entries(stateObj).forEach(([key, value]) => {
            this.setState({ [key]: value });
        });
    }

    handleErrorChanges = (code, msg) => {
        let ind = this.state.errorMessages.indexOf(msg);
        if (ind > -1) {
            this.state.errorMessages.splice(ind, 1);
        }
        this.setState({ [`${code}_error`]: false });
    }
}

export default AddDefaultComponent
