import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Masonry from 'react-masonry-css'
import { hoverHelpers } from 'shared-helpers';

// api
import ecosystemMarketMapApi from '/src/apis/ecosystemMarketMapApi';

// helpers
import { prepareSelectParam, prepareFilterParam, prepareSortParam } from '/src/lib/queryParams';

// reusable
import MagnittLoader from '/src/micro-frontends/MagnittLoader';
import { VerifiedFundingNote } from '/src/pages/directories/reusable/VerifiedFundingNote';

// constants
import { ALLOWED_QUERY_OPERATOR } from '/src/lib/constants';
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';
import { NO_DATA } from '/src/lib/messages';



class InvestorMarketMapLandscape extends Component {

    constructor(props) {
        super(props);

        // set a default grouping if none was selected
        // (this only affects testapp maps where the 'group by' field was reset)
        if (props.group.length > 0) {
            this.view = props.group[0].name.replace(/ +/g, "");
        } else {
            this.view = "InvestorCountry";
        }

        this.state = {
            page: 1,
            showPageLoading: false,
            showCardsLoading: false,
            isInvestorCountry: this.view === "InvestorCountry" ? true : false,
            isInvestorType: this.view === "InvestorType" ? true : false,
            isInvestmentCountry: this.view === "InvestmentCountry" ? true : false,
            isInvestmentIndustry: this.view === "InvestmentIndustry" ? true : false,
            isInvestmentStage: this.view === "InvestmentStage" ? true : false,
            currentView: this.view,
            breakpointColumnsObj: {
                default: 3,
                1024: 2,
                600: 1
            },
            paramFilters: [
                { key: 'status', value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }
            ],
            cardLimit: 12
        };
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            marketMapData: this.props.data.marketMapData,
            showPageLoading: true,
            filter: data.filter,
            limit: this.props.data.limit
        }, () => {
            this.getNetworkMap();
        });
    }

    getNetworkMapFilters() {
        const { paramFilters, filter } = this.state;
        let customFilters = prepareFilterParam(filter);
        let defaultFilters = prepareFilterParam(paramFilters);

        let filters = customFilters ? defaultFilters + `^,${customFilters}` : defaultFilters;
        return filters;
    }

    getNetworkMapFields() {
        let { currentView } = this.state;
        let fields, path, sort = [{ key: 'category_entity_count', sortVal: 'desc' }];

        switch (currentView) {
        case 'InvestorCountry':
            fields = ['category_country_id', 'category_country_name', 'category_entity_count', 'investor_detail_data', 'investors_data'];
            path = 'market-map';
            sort.push({ key: 'category_country_name', sortVal: 'desc' })
            break;
        case 'InvestorType':
            fields = ['category_investor_type_id', 'category_investor_type_name', 'category_entity_count', 'investor_detail_data', 'investors_data'];
            path = 'market-map';
            sort.push({ key: 'category_investor_type_name', sortVal: 'desc' })
            break;
        case 'InvestmentCountry':
            fields = ['category_startup_country_id', 'category_startup_country_name', 'category_entity_count', 'investor_detail_data', 'investors_data'];
            path = 'funding-market-map';
            sort.push({ key: 'category_startup_country_name', sortVal: 'desc' })
            break;
        case 'InvestmentIndustry':
            fields = ['category_primary_industry_id', 'category_primary_industry_name', 'category_entity_count', 'investor_detail_data', 'investors_data'];
            path = 'funding-market-map';
            sort.push({ key: 'category_primary_industry_name', sortVal: 'desc' })
            break;
        case 'InvestmentStage':
            fields = ['category_investment_stage_id', 'category_investment_stage_name', 'category_entity_count', 'investor_detail_data', 'investors_data'];
            path = 'funding-market-map';
            sort.push({ key: 'category_investment_stage_name', sortVal: 'desc' })
            break;
        }

        return ({ path, fields, sort })
    }

    async getNetworkMap() {
        let responseData;
        let filters = this.getNetworkMapFilters();
        let params = this.getNetworkMapFields();

        const fields = prepareSelectParam(params.fields);
        const sortParams = prepareSortParam(params.sort);
        await ecosystemMarketMapApi.getEcosystemDirectoryLandscape(params.path, fields, filters, this.state.limit, this.state.page, sortParams).then((response) => {
            if (!isEmpty(response)) {
                responseData = response;
            }
        })

        this.setState(prevState => ({
            marketMapData: {
                ...prevState.marketMapData,
                data: [...prevState.marketMapData.data, ...responseData.records],
                count: responseData.count
            },
            showCardsLoading: false,
            showPageLoading: false
        }));
    }

    changeView(currentView) {
        let obj = {};
        obj[currentView] = true;

        this.setState(prevState => ({
            isInvestorCountry: false,
            isInvestorType: false,
            isInvestmentCountry: false,
            isInvestmentIndustry: false,
            isInvestmentStage: false,
            showPageLoading: true,
            currentView: currentView.replace('is', ''),
            page: 1,
            marketMapData: {
                ...prevState.marketMapData,
                data: [],
                count: 0
            }
        }), () => {
            this.setState(obj);
            this.getNetworkMap();
        })

        //remove landscape tooltips when tab is changed
        $(".popover").remove();
    }

    showMoreCards() {
        let newPage = this.state.page;
        newPage++
        this.setState({
            page: newPage,
            showCardsLoading: true
        }, () => {
            this.getNetworkMap();
        })
    }

    showMoreItems = (card, index) => {
        const { cardLimit } = this.state;
        let filters = this.getNetworkMapFilters();

        // category filter
        if (card.category_id) {
            let params = this.getNetworkMapFields();

            let page = card.category_page ? card.category_page + 1 : 2;
            let remaining = card.category_entity_count - (page - 1) * cardLimit;

            let mapData = this.state.marketMapData.data;
            mapData[index].showItemsLoading = true;
            this.setState(prevState => ({
                marketMapData: {
                    ...prevState.marketMapData,
                    data: mapData
                }
            }));

            const fields = prepareSelectParam(params.fields);
            const sortParams = prepareSortParam(params.sort);
            ecosystemMarketMapApi.getEcosystemLandscapePagination(params.path, card.category_id, fields, filters, cardLimit, page, sortParams).then((response) => {
                if (!isEmpty(response)) {
                    mapData = this.state.marketMapData.data;
                    mapData[index].category_page = page;
                    mapData[index].showItemsLoading = false;

                    let responseData = remaining > cardLimit ? response : response.slice(0, `${remaining}`);
                    mapData[index].investor_detail_data = mapData[index].investor_detail_data.concat(responseData);

                    this.setState(prevState => ({
                        marketMapData: {
                            ...prevState.marketMapData,
                            data: mapData
                        }
                    }));
                }
            })
        }
    }

    imgMissing(event) {
        event.target.style.opacity = 0;
        event.target.parentNode.style['border-radius'] = '7px';
        event.target.parentNode.style['background-color'] = '#F0F0F0';
    }

    renderItemImage(item) {
        if (item.logo_path) {
            let img = encodeURI(item.logo_path);
            return (<img className="entity-image" itemProp="image" src={img} onError={(event) => this.imgMissing(event, item)} width="100" height="100" loading="lazy" />)
        } else {
            return (<div className="entity-image-div">{item.name.charAt(0).toUpperCase()}</div>)
        }
    }
   
    renderCardItems = (cardItems) => {

        // check for directory access
        // it's required to view some fields (e.g. estimated capital deployed)
        const { user } = this.props;
        let hasDirAccess = false;
        if (user && user.roles && user.roles.length > 0) {
            hasDirAccess = (user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access) || user.roles.includes(MAGNITT_ACCESS_ROLES.limited_directory_access))
        }

        let items = [];
        cardItems.forEach((item) => {
            item.type = item.entity_type.toLowerCase();
            item.id = item.entity_id;
            items.push(
                <span key={`item-` + item.entity_id}>
                    <a className="map-card__entity" target="_blank" rel="noopener noreferrer" href={`/${item.type}s/` + item.code + `-` + item.entity_id}>
                        <div className="entity-icon" 
                            onMouseEnter={(event) => hoverHelpers.handleEntityHover(item, event, hasDirAccess)} 
                            onMouseLeave={() => hoverHelpers.handleMouseOut()}>
                            {this.renderItemImage(item)}
                        </div>
                    </a>
                </span>
            )
        });
        return items;

    }

    renderMapCards() {
        let cards = this.state.marketMapData.data;
        let cardsRender = [];
        cards.forEach((card, index) => {
            cardsRender.push(
                <section className="map-card fadezoom" key={`card-` + card.category_id}>
                    <div className="map-box card">
                        <div className="card__header">
                            <div className="card__title">
                                {card.category_name}
                                <span className="float-right">({card.category_entity_count})</span>
                            </div>
                        </div>
                        <div className="card__content">
                            <div className="map-card__items">
                                {this.renderCardItems(card.investor_detail_data)}
                            </div>
                            {card.category_entity_count > card.investor_detail_data.length &&
                                <div className="card__more">
                                    <a onClick={() => this.showMoreItems(card, index)}>
                                        {card.showItemsLoading &&
                                            <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                        }
                                        Show More
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            )
        });

        return cardsRender;
    }

    render() {

        const { group, tabIsVisible } = this.props;
        const { showPageLoading, breakpointColumnsObj, marketMapData, showCardsLoading } = this.state;
        const hasMapData = (marketMapData && marketMapData.data.length > 0);

        return (
            <div className="network-map-main network-map-cards">

                <VerifiedFundingNote entityType="investor" />

                {/* display 'groups' in same order as user's selection */}
                {/* hide if only 1 grouping button is present */}
                {(group.length > 1) &&
                    <div className="network-map-groups">
                        {group.map((grp, index) => {
                            let code = "is" + grp.name;
                            code = code.replace(" ", "");
                            return (
                                <span key={`group-` + index} onClick={() => this.changeView(code)} className={"group-buttons " + (this.state[code] ? 'active' : 'inactive')}>
                                    {grp.name}
                                </span>
                            )
                        })}
                    </div>
                }

                {showPageLoading && tabIsVisible &&
                    <MagnittLoader data={{ height: "300px", background: "transparent" }} />
                }

                {hasMapData &&
                    <div className="map-card-wrapper">
                        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {this.renderMapCards()}
                        </Masonry>
                    </div>
                }

                {!showPageLoading && !hasMapData &&
                    <div className="no-chart-data">
                        <h6>{NO_DATA}</h6>
                    </div>
                }

                {this.props.view !== 'preview' && !showPageLoading && marketMapData && marketMapData.data.length < marketMapData.count &&
                    <div className="d-flex justify-content-center mt-4" >
                        <a className="btn btn-main" onClick={() => this.showMoreCards()}>
                            {showCardsLoading ?
                                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                :
                                <i className="fas fa-arrow-circle-down mr-2"></i>
                            }
                            Show More
                        </a>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps)(InvestorMarketMapLandscape);
