import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import axios from 'axios';

// helpers
import { prepareSelectParam, prepareFilterParam } from '../lib/queryParams';
import { sortObject } from '/src/lib/helpers';

// constants
import { API_URL_NEW } from '../lib/endpoints'
import { ALLOWED_QUERY_OPERATOR, ENTITY_STATUS, ENTITY_TYPE, FUNDING_STAGES, FUNDING_STATUS } from '../lib/constants';



const fundingApi = {

    getFundingRoundsData: (selectableFields = [], filters = '', limit, page, sort) => {

        const fields = prepareSelectParam([
            'id', 'investedin_id', 'investedin_data',
            ...selectableFields
        ])
        const arrFilters = [
            { key: 'investedin_type_filter', value: [ENTITY_TYPE.startup], op: ALLOWED_QUERY_OPERATOR.equal }
        ];

        // exclude acquisition and draft rounds
        if (filters.indexOf('funding_stage_filter') === -1) {
            arrFilters.push({
                key: 'funding_stage_filter',
                value: [FUNDING_STAGES.acquisition],
                op: ALLOWED_QUERY_OPERATOR.notEq
            });
        }
        if (filters.indexOf('verification_status') === -1) {
            arrFilters.push({
                key: 'verification_status',
                value: [FUNDING_STATUS.draft],
                op: ALLOWED_QUERY_OPERATOR.notEq
            })
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/funding-breakdown?fields=${fields}&filters=${filters}^,${permanentFilters}&limit=${limit}&page=${page}&sort=${sort}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else { resolve([]) }
            }).catch((err) => reject(err));
        });
    },

    getFundingRoundsCount: (filters = '') => {
        const arrFilters = [
            { key: 'investedin_type', value: ['startup'], op: ALLOWED_QUERY_OPERATOR.equal }
        ];

        if (filters.indexOf('funding_stage_id') === -1) {
            arrFilters.push({ key: 'funding_stage_id', value: [FUNDING_STAGES.acquisition], op: ALLOWED_QUERY_OPERATOR.notEq }); // exclude acquisition stage
        }

        if (filters.indexOf('verification_status') === -1) {
            arrFilters.push({ key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/funding/setting/count?filters=${filters}^,${permanentFilters}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        count: response.data.data
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    getFundingBreakdownsCount: (fundingFilters = [], filters = '') => {

        const arrFilters = [
            { key: 'investedin_type_filter', value: [ENTITY_TYPE.startup], op: ALLOWED_QUERY_OPERATOR.equal }
        ];

        // add investedin_status_filter if not present in filters
        if (!fundingFilters?.investedin_status_filter?.length > 0 || !filters?.finalFilters?.includes('investedin_status_filter')) {
            arrFilters.push({
                key: 'investedin_status_filter',
                value: [
				 	ENTITY_STATUS.claimedOrAdded,
				 	ENTITY_STATUS.invited
                ],
                op: ALLOWED_QUERY_OPERATOR.equal
            });
        }

        if (!fundingFilters.funding_stage || fundingFilters.funding_stage.length == 0) {
            arrFilters.push({ key: 'funding_stage_filter', value: [FUNDING_STAGES.acquisition], op: ALLOWED_QUERY_OPERATOR.notEq }); // exclude acquisition stage
        }

        if (!fundingFilters.funding_verification_status) {
            arrFilters.push({ key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        let filterStr = `filters=`;
        if (filters.finalFilters) {
            filterStr += `${filters.finalFilters}^,`;
        }
        filterStr += `${permanentFilters}`;

        const url = `${API_URL_NEW}/funding-breakdown/setting/count?${filterStr}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        count: response.data.data
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    getFundingData: (id, entityType, recordsToLoad, getFilters = [], pathName, page) => {

        return new Promise((resolve, reject) => {

            if (entityType === "startup") {

                let arrFilters = [];

                //case 1: no filters, or verification filter is missing - add mandatory filter to exclude draft funding rounds, draft: verification_status = 3
                //case 2: use user specified filters
                if (getFilters.length === 0 || !find(getFilters, 'key', 'verification_status_filter')) {
                    arrFilters.push({
                        key: 'verification_status_filter',
                        value: [FUNDING_STATUS.draft],
                        op: ALLOWED_QUERY_OPERATOR.notEq
                    });
                } else {
                    arrFilters = [...getFilters];
                }

                let fieldsList = ['id', 'amount_raised', 'date_raised', 'funding_stage_data', 'investedin_data', 'investors_data', 'funding_breakdowns_data', 'post_money_valuation', 'location_data', 'valuation'];

                // removing  post_money_valuation  field if page is single startup profile
                if (pathName && pathName.includes("startups/")) {
                    fieldsList = fieldsList.filter(field => field !== 'post_money_valuation');
                }

                const filters = prepareFilterParam(arrFilters);

                const fields = prepareSelectParam(fieldsList);

                let url = `${API_URL_NEW}/startup/${id}/funding?fields=${fields}&filters=${filters}&limit=${recordsToLoad}&sort=date_raised^:DESC`;
                if (page) {
                    url += `&page=${page}`;
                }

                axios.get(url).then(response => {

                    let resp = response.data.data;

                    if (resp && resp.length > 0) {

                        // exclude invalid test data
                        let rounds = [];
                        for (let i = 0; i < resp.length; i++) {
                            if (resp[i].investedin_data && resp[i].funding_breakdowns_data.length > 0) {
                                resp[i].funding_breakdowns_data = sortObject(
                                    resp[i].funding_breakdowns_data, "date_raised"
                                )
                                resp[i].date_raised = resp[i].funding_breakdowns_data[0].date_raised
                                rounds.push(resp[i])
                            }
                        }

                        rounds = sortObject(rounds, "date_raised");

                        let latestStage = 'N/A';
                        if (!isEmpty(resp[0].funding_stage_data)) {
                            latestStage = resp[0].funding_stage_data.name;
                        }

                        resolve({
                            rounds,
                            latestStage,
                            totalRecords: response.data.navigation.total,
                        });

                    } else {

                        resolve([])

                    }

                }).catch(err => reject(err))

            } else if ((entityType === "user") || (entityType === "investor") || (entityType === "enabler") || (entityType === "corporate")) {

                const investedFields = prepareSelectParam(['funding_id', 'funding_stage_data', 'invested_amount', 'investedin_data', 'total_amount', 'date_raised', 'investors_data', 'created_at', 'verification_status', 'funding_breakdowns_data', 'valuation', 'fund_manager_data']);

                // add mandatory filter to exclude draft funding rounds, draft: verification_status = 3
                const arrFilters = [...getFilters, { key: 'verification_status_filter', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }];
                const filters = prepareFilterParam(arrFilters);

                // handled this way to avoid breaking other pages that rely on this API call
                let url = `${API_URL_NEW}/${entityType}/${id}/invested/?fields=${investedFields}&filters=${filters}&limit=${recordsToLoad}&sort=date_raised^:desc`;
                if (page) {
                    url += `&page=${page}`;
                }

                axios.get(url).then(response => {

                    let rounds = response.data.data

                    // ignore invalid test data
                    let cleanRounds = [];
                    rounds.map((item, i) => {
                        if (item.investedin_data) {
                            cleanRounds.push(item);
                        }
                    })

                    resolve({
                        rounds: cleanRounds,
                        totalRecords: response.data.navigation.total
                    });

                }).catch(err => reject(err))

            }

        })
    },

    // used to check if funding round exists for current filters
    getFundingRoundData: (startupId, stage) => {
        const fields = prepareSelectParam(["id", "entity_id", "entity_type", "amount_raised", "post_money_valuation", 'funding_breakdowns_data', 'investors_data'])
        const filters = prepareFilterParam([{ key: 'investedin_id', value: [startupId], op: ALLOWED_QUERY_OPERATOR.equal }, { key: 'funding_stage_id', value: [stage], op: ALLOWED_QUERY_OPERATOR.equal }])

        const url = `${API_URL_NEW}/funding?fields=${fields}&filters=${filters}&limit=1`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        fundingRound: response.data.data,
                    });
                } else {
                    resolve([]);
                }
            }).catch((err) => reject(err));
        });
    },

    checkForDuplication: (startupId, stage) => {

        const filters = prepareFilterParam([
            {
                key: 'investedin_id',
                value: [startupId],
                op: ALLOWED_QUERY_OPERATOR.equal
            },
            {
                key: 'funding_stage_id',
                value: [stage],
                op: ALLOWED_QUERY_OPERATOR.equal
            },
            {
                key: 'is_deleted',
                value: [0],
                op: ALLOWED_QUERY_OPERATOR.equal
            },
            {
                key: 'verification_status_filter',
                value: [FUNDING_STATUS.draft],
                op: ALLOWED_QUERY_OPERATOR.notEq
            }
        ]);

        const url = `${API_URL_NEW}/funding/setting/count?filters=${filters}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(async (response) => {
                resolve(response.data.data);
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "2,700"
                }
                console.log("ERROR: ", err);
                reject(data);
            })
        })
    },

    // get total funding rounds
    getTotalFundingRoundsCount: () => {
        //  add mandatory filter to exclude draft funding rounds, draft: verification_status = 3
        const filters = prepareFilterParam([
            { key: 'verification_status', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq },
            { key: 'funding_stage_id', value: [FUNDING_STAGES.acquisition], op: ALLOWED_QUERY_OPERATOR.notEq } // exclude acquisition stage
        ]);

        const url = `${API_URL_NEW}/funding/setting/count?filters=${filters}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(async (response) => {
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "10,000"
                }
                console.log("ERROR: ", err);
                reject(data);
            })
        })
    },

    getLPFundingRoundsData: (selectableFields = [], filters = '', limit, page, sort) => {

        const fields = prepareSelectParam([
            'id', 'name', 'code', 'entity_data', 'funding_breakdown_id',
            ...selectableFields
        ]);

        const arrFilters = [];
        if (filters.indexOf('verification_status_filter') === -1) {
            arrFilters.push({ key: 'verification_status_filter', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/fund/funding?fields=${fields}&filters=${filters}^,${permanentFilters}&limit=${limit}&page=${page}&sort=${sort}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status == 200 && response.data.data) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    getLPFundingRoundsCount: (filters = '') => {
        const arrFilters = [];
        if (filters.indexOf('verification_status_filter') === -1) {
            arrFilters.push({ key: 'verification_status_filter', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }) // exclude draft funding rounds
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/fund/setting/count?filters=${filters}^,${permanentFilters}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200) {
                    resolve({
                        count: response.data.data
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

    getRecentFundingRoundsData: (selectableFields = [], filters = '', limit, page, sort) => {
        const fields = prepareSelectParam(selectableFields);

        const arrFilters = [
            { key: 'investedin_type_filter', value: ['startup'], op: ALLOWED_QUERY_OPERATOR.equal }
        ];

        // exclude draft rounds
        if (filters.indexOf('verification_status') === -1) {
            arrFilters.push({
                key: 'verification_status',
                value: [FUNDING_STATUS.draft],
                op: ALLOWED_QUERY_OPERATOR.notEq
            })
        }

        const permanentFilters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/funding-breakdown/recent-funding?fields=${fields}&filters=${filters}^,${permanentFilters}&limit=${limit}&page=${page}&sort=${sort}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                const resp = response.data.data;
                resolve({
                    records: resp,
                    count: response.data.navigation.total
                });
            }).catch((err) => reject(err));
        });
    },

}

export default fundingApi;
