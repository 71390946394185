import { prepareSelectParam, prepareFilterParam } from '../lib/queryParams'
import { API_URL_NEW } from '../lib/endpoints'
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { ALLOWED_QUERY_OPERATOR, EXCEPTION_INDUSTRIES_AND, EXCEPTION_INDUSTRIES_SLASH, MENA_COUNTRIES_IDS, PAKISTAN_COUNTRY_ID, TOP_INDUSTRIES_IDS, TURKEY_COUNTRY_ID } from '../lib/constants';
import isArray from 'lodash/isArray';

const taxonomyApi = {
    getTaxonomyType: (taxonomyType, maxLimit = 300, taxoFields) => {
        let fields = '';
        if (!taxoFields) {
            fields = prepareSelectParam(['name', 'id']);
        } else {
            fields = taxoFields
        }

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/taxonomy-type/${taxonomyType}/taxonomy?fields=${fields}&limit=${maxLimit}`).then(res => {
                resolve(res.data.data)
            }).catch(err => reject(err))
        })
    },

    getLocations: (maxLimit = 300, objRegionFilter = {}) => {
        let arrFilters = [{ key: 'country', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }];

        if (!isEmpty(objRegionFilter)) {
            arrFilters.push(objRegionFilter);
        } else {
            const defaultRegionFilter = { key: 'is_region', value: [0], op: ALLOWED_QUERY_OPERATOR.equal };
            arrFilters.push(defaultRegionFilter);
        }

        const taxoFields = prepareSelectParam(['name', 'id']);
        const filters = prepareFilterParam(arrFilters);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location/?fields=${taxoFields}&filters=${filters}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getCities: (countryId, filters = "", maxLimit = 20) => {
        const taxoFields    = prepareSelectParam(['id', 'name']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location/${countryId}/city?fields=${taxoFields}&filters=${filters}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getAllCities: (filterParams = [], maxLimit = 20) => {
        const hasCountryFilter = filterParams.find(filter => filter.key === 'country');

        if (!hasCountryFilter) {
            filterParams.push({ key: 'country', value: [0], op: ALLOWED_QUERY_OPERATOR.gt });
		 }

        const taxoFields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam(filterParams);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location?fields=${taxoFields}&filters=${filters}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getIndustries: (maxLimit = 300) => {
        const taxoFields = prepareSelectParam(['name', 'id']);
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
        ]);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry?fields=${taxoFields}&filters=${filters}&limit=${maxLimit}`)
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => {
                    reject(err)
                })
        })
    },

    getSubIndustriesList: () => {
        const taxoFields = prepareSelectParam(['name', 'id']);
        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: [0], op: ALLOWED_QUERY_OPERATOR.notEq },
        ]);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry?fields=${taxoFields}&filters=${filters}&limit=200&sort=name^:asc`)
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => {
                    reject(err)
                })
        })
    },

    getSubIndustries: (parentId) => {
        const taxoFields = prepareSelectParam(['name', 'id']);

        let parentIdFilter = [parentId];
        if (isArray(parentId)) {
            parentIdFilter = [...parentId];
        }

        const filters = prepareFilterParam([
            { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            { key: 'parent_id', value: parentIdFilter, op: ALLOWED_QUERY_OPERATOR.equal },
        ]);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry?fields=${taxoFields}&filters=${filters}&limit=200`)
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => {
                    reject(err)
                })
        })
    },

    getResearchCategories: () => {
        let taxoFields = prepareSelectParam(['name', 'id']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/research/setting/research-category?fields=${taxoFields}`)
                .then(response => {
                    resolve({
                        records: response.data.data
                    });
                }).catch(err => {
                    reject(err)
                })
        })
    },

    getResearchTags: () => {
        let taxoFields = prepareSelectParam(['name', 'id']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/research-taxonomy?fields=${taxoFields}`)
                .then(response => {
                    resolve({
                        records: response.data.data
                    });
                }).catch(err => {
                    reject(err)
                })
        })
    },

    getTaxonomyTypeById: (taxonomyType, taxonomyTypeId) => {
        let taxoFields = prepareSelectParam(['name', 'id']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/taxonomy-type/${taxonomyType}/taxonomy/${taxonomyTypeId}?fields=${taxoFields}`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve({
                            record: response.data.data
                        });
                    }
                }).catch(err => reject(err))
        })
    },

    getIndustryById: (industryId) => {
        let taxoFields = prepareSelectParam(['name', 'id']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry/${industryId}?fields=${taxoFields}`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve({
                            record: response.data.data
                        });
                    }
                }).catch(err => reject(err))
        })
    },

    getLocationById: (locationId) => {
        let taxoFields = prepareSelectParam(['name', 'id']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location/${locationId}?fields=${taxoFields}`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve({
                            record: response.data.data
                        });
                    }
                }).catch(err => reject(err))
        })
    },

    getLocationByISOCode: (isoCode) => {
        let taxoFields = prepareSelectParam(['id', 'name', 'iso_code']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location?fields=${taxoFields}&filters=iso_code^:${isoCode}`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve(response.data.data);
                    }
                }).catch(err => reject(err))
        })
    },

    getIndustryByCode: (industryCode) => {
        let taxoFields = prepareSelectParam(['id', 'name', 'code', 'parent_id']);

        if (EXCEPTION_INDUSTRIES_SLASH.includes(industryCode)) {
            industryCode = industryCode.replace('-','/')
        }

        if (EXCEPTION_INDUSTRIES_AND.includes(industryCode)) {
            industryCode = industryCode.replace('and','&')
        }

        let industryCodeEncoded
        const decodedIndustry = decodeURIComponent(industryCode.toLowerCase())
        if (decodedIndustry == industryCode.toLowerCase()) {
            industryCodeEncoded = encodeURIComponent(industryCode.toLowerCase())
        } else {
            industryCodeEncoded = industryCode.toLowerCase()
        }

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry?fields=${taxoFields}&filters=code^:${industryCodeEncoded}`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve(response.data.data);
                    }
                }).catch(err => reject(err))
        })
    },

    getMENACountries: () => {
        const taxoFields = prepareSelectParam(['id', 'name', 'iso_code']);
        const filters = prepareFilterParam([{key: 'id', value: [...MENA_COUNTRIES_IDS, PAKISTAN_COUNTRY_ID, TURKEY_COUNTRY_ID], op: ALLOWED_QUERY_OPERATOR.equal}])

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location/?fields=${taxoFields}&filters=${filters}&limit=200`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve(response.data.data);
                    }
                }).catch(err => reject(err))
        })
    },

    getTopIndustries: () => {
        const taxoFields = prepareSelectParam(['id', 'name', 'code']);
        const filters = prepareFilterParam([{key: 'id', value: TOP_INDUSTRIES_IDS, op: ALLOWED_QUERY_OPERATOR.equal}])

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/industry/?fields=${taxoFields}&filters=${filters}&limit=200`)
                .then(response => {
                    if (response.data && !isEmpty(response.data.data)) {
                        resolve(response.data.data);
                    }
                }).catch(err => reject(err))
        })
    },

    getRegions: (maxLimit = 300) => {
        const taxoFields = prepareSelectParam(['id', 'name']);
        const filters = prepareFilterParam([
            { key: 'is_region', value: [1], op: ALLOWED_QUERY_OPERATOR.equal }
        ]);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/location/?fields=${taxoFields}&filters=${filters}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getJobRoles: (maxLimit = 300) => {
        const taxoFields = prepareSelectParam(['id', 'name']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/team-role/?fields=${taxoFields}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },

    getDegrees: (maxLimit = 300) => {
        const taxoFields = prepareSelectParam(['id', 'name']);

        return new Promise((resolve, reject) => {
            axios.get(`${API_URL_NEW}/academic-degree/?fields=${taxoFields}&limit=${maxLimit}`, {})
                .then(response => {
                    resolve(response.data.data)
                }).catch(err => reject(err))
        })
    },
}

export default taxonomyApi
