import loadable from '@loadable/component';

import * as api from '../server/api'

const Home = loadable(() => import(/* webpackChunkName: "Home" */ './components/home/Home'));
const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ '../src_temp/components/User/LoginPage'));

// directories - new
const Startup = loadable(() => import(/* webpackChunkName: "Startup" */ './pages/directories/Startup.js'));
const ClientDirectory = loadable(() => import(/* webpackChunkName: "ClientDirectory" */ './pages/directories/ClientDirectory.js'));
const Ecosystem = loadable(() => import(/* webpackChunkName: "Ecosystem" */ './pages/directories/Ecosystem.js'));
const LimitedPartnersTracker = loadable(() => import(/* webpackChunkName: "LimitedPartnersTracker" */ './pages/directories/LimitedPartnersTracker.js'));
const People = loadable(() => import(/* webpackChunkName: "People" */ './pages/directories/People.js'));
const PrivateEquity = loadable(() => import(/* webpackChunkName: "PrivateEquity" */ './pages/directories/PrivateEquity.js'));

const Follow = loadable(() => import(/* webpackChunkName: "Follow" */ '../src_temp/components/DirectoryTable/Follow'));

const Newsletters = loadable(() => import(/* webpackChunkName: "Newsletters" */ '../src_temp/components/Pages/Newsletters'));
const PricingPage = loadable(() => import(/* webpackChunkName: "PricingPage" */ '../src_temp/components/Purchase/PricingPage'));
const BuyMagnitt = loadable(() => import(/* webpackChunkName: "BuyMagnitt" */ '../src_temp/components/Purchase/BuyMagnitt'));
const Preferences = loadable(() => import(/* webpackChunkName: "Preferences" */ '../src_temp/components/Pages/Preferences'));

const FAQ = loadable(() => import(/* webpackChunkName: "FAQ" */ '../src_temp/components/Pages/FAQ'));
const AccountSettings = loadable(() => import(/* webpackChunkName: "AccountSettings" */ '../src_temp/components/Pages/AccountSettings'));
const AboutUs = loadable(() => import(/* webpackChunkName: "AboutUs" */ '/src/pages/about/AboutUs'));
const TermsofService = loadable(() => import(/* webpackChunkName: "TermsofService" */ '../src_temp/components/Pages/TermsofService'));
const TermsofResearch = loadable(() => import(/* webpackChunkName: "TermsofResearch" */ '../src_temp/components/Pages/TermsofResearch'));
const AnalystHoursUsagePolicy = loadable(() => import(/* webpackChunkName: "AnalystHoursUsagePolicy" */ '../src_temp/components/Pages/AnalystHoursUsagePolicy.js'));
const PrivacyandCookies = loadable(() => import(/* webpackChunkName: "PrivacyandCookies" */ '../src_temp/components/Pages/PrivacyandCookies'));
const ContactUs = loadable(() => import(/* webpackChunkName: "ContactUs" */ '../src_temp/components/Pages/ContactUs'));
const MarketMapUAE = loadable(() => import(/* webpackChunkName: "MarketMapUAE" */ './components/marketMap/MarketMapUAE'));
const MarketMapLEAP = loadable(() => import(/* webpackChunkName: "MarketMapLEAP" */ './components/marketMap/MarketMapLEAP'));
const MarketMapNorthStar = loadable(() => import(/* webpackChunkName: "MarketMapNorthStar" */ './components/marketMap/MarketMapNorthStar'));

// single sice


const StartupPage = loadable(
    () => import(/* webpackChunkName: "StartupPage" */ '/src_temp/components/Single-SICE/StartupPage'));
const ICEPage = loadable(
    () => import(/* webpackChunkName: "ICEPage" */ '/src_temp/components/Single-SICE/ICEPage'));

const SingleUser = loadable(
    () => import(/* webpackChunkName: "SingleUser" */ '/src_temp/components/Single-SICE/SingleUser'));
const SinglePerson = loadable(
    () => import(/* webpackChunkName: "SinglePerson" */ '/src_temp/components/Single-SICE/SinglePerson'));
const CreateEditSICE = loadable(
    () => import(/* webpackChunkName: "CreateEditSICE" */ '/src_temp/components/Single-SICE/CreateEditSICE'));

// end // single sice

const SingleJob = loadable(() => import(/* webpackChunkName: "SingleJob" */ '/src_temp/components/Careers/SingleJobs'));
const ManageJobs = loadable(() => import(/* webpackChunkName: "ManageJobs" */ '/src_temp/components/Manage/ManageJobs'));
const ManageSingleJob = loadable(() => import(/* webpackChunkName: "ManageSingleJob" */ '/src_temp/components/Manage/ManageSingleJob'));

const ManageCompany = loadable(() => import(/* webpackChunkName: "ManageCompany" */ '../src_temp/components/Manage/ManageCompany'));
const EditJob = loadable(() => import(/* webpackChunkName: "EditJob" */ '../src_temp/components/Manage/EditJob'));

const PaymentSuccess = loadable(() => import(/* webpackChunkName: "PaymentSuccess" */ '../src_temp/components/Pages/PaymentSuccess'));
const VerifyEmailSuccess = loadable(() => import(/* webpackChunkName: "VerifyEmailSuccess" */ '../src_temp/components/Pages/VerifyEmailSuccess'));
const DownloadSuccess = loadable(() => import(/* webpackChunkName: "DownloadSuccess" */ '../src_temp/components/Pages/DownloadSuccess'));
const SavedSearchDashBoard = loadable(() => import(/* webpackChunkName: "SavedSearchDashBoard" */ './pages/SavedSearchDashBoard/SavedSearchDashBoard'));
const Marketing = loadable(() => import(/* webpackChunkName: "Marketing" */ '../src_temp/components/Pages/Marketing'));

const ManageCard = loadable(() => import(/* webpackChunkName: "ManageCard" */ '../src_temp/components/Manage/ManageCard'));
const PaymentHistory = loadable(() => import(/* webpackChunkName: "PaymentHistory" */ '../src_temp/components/Manage/PaymentHistory'));

const VerifyAddress = loadable(() => import(/* webpackChunkName: "VerifyAddress" */ '../src/components/accountSettings/VerifyAddress'));

const NotificationsPage = loadable(() => import(/* webpackChunkName: "NotificationsPage" */ './pages/NotificationsPage'));
const RedirectionPage = loadable(() => import(/* webpackChunkName: "RedirectionPage" */ './pages/RedirectionPage'));
const ThankYouPage = loadable(() => import(/* webpackChunkName: "ThankYouPage" */ './pages/ThankYouPage'));

// tableau dashboards
const ExitAnalysisPage = loadable(() => import(/* webpackChunkName: "ExitAnalysisPage" */ './pages/tableauDashboards/ExitAnalysisPage'));
const MarketSizingPage = loadable(() => import(/* webpackChunkName: "MarketSizingPage" */ './pages/tableauDashboards/MarketSizingPage'));
const StartupAnalyticsPage = loadable(() => import(/* webpackChunkName: "StartupAnalyticsPage" */ './pages/tableauDashboards/StartupAnalyticsPage'));
const CustomDashboardPage = loadable(() => import(/* webpackChunkName: "CustomDashboardPage" */ './pages/tableauDashboards/CustomDashboardPage'));
const DealSizeAnalysisPage = loadable(() => import(/* webpackChunkName: "DealSizeAnalysisPage" */ './pages/tableauDashboards/DealSizeAnalysisPage'));
const GeoBenchmarkPage = loadable(() => import(/* webpackChunkName: "GeoBenchmarkPage" */ './pages/tableauDashboards/GeoBenchmarkPage'));
const IndustryBenchmarkPage = loadable(() => import(/* webpackChunkName: "IndustryBenchmarkPage" */ './pages/tableauDashboards/IndustryBenchmarkPage'));
const InvestorRankingPage = loadable(() => import(/* webpackChunkName: "InvestorRankingPage" */ './pages/tableauDashboards/InvestorRankingPage'));
const CustomAnalyticsPage = loadable(() => import(/* webpackChunkName: "CustomAnalyticsPage" */ './pages/tableauDashboards/CustomAnalyticsPage'));

import { Jobs } from '/src_temp/components/Careers/Jobs';
import { Internships } from '/src_temp/components/Careers/Internships';
import NoMatch from './micro-frontends/NoMatch';
import FeatureUnavailable from './micro-frontends/FeatureUnavailable';

const ContactSalesForm = loadable(
    () => import(/* webpackChunkName: "ContactSalesForm" */ './pages/contactSales/ContactSalesForm')
);

const DemoRequestForm = loadable(
    () => import(/* webpackChunkName: "DemoRequestForm" */ './pages/contactSales/DemoRequestForm')
);

const BespokeReportsForm = loadable(
    () => import(/* webpackChunkName: "BespokeReportsForm" */ './pages/contactSales/BespokeReportsForm')
);

// typeforms
const AfricaNewsletterForm = loadable(() => import(/* webpackChunkName: "AfricaNewsletterForm" */ './pages/forms/AfricaNewsletterForm'));

// tableau dashboards
const AdvancedAnalyticsDashboard = loadable(() => import(/* webpackChunkName: "AdvancedAnalyticsDashboard" */ './pages/tableauDashboards/AdvancedAnalyticsPage'));

// custom market maps
import CustomMarketMaps from './pages/customMarketMaps/CustomMarketMaps';
import AddEditCustomMap from './pages/customMarketMaps/AddEditCustomMap';
import ViewCustomMap from './pages/customMarketMaps/ViewCustomMap';
import DefaultMarketMap from './pages/customMarketMaps/components/marketMap/DefaultMarketMap';



// set ssr helmet data for components where the title can vary based on the route
const helmetSubscribe = {
    title: 'Subscribe | MAGNiTT',
    metaContent: 'MAGNiTT has a subscription offering that provides access to data, research and analytics on the Middle East, Africa, Turkey and Pakistan venture funding ecosystem. Access to reports, interactive dashboards and exports to CSV.',
    ogTitle: 'Subscribe to MAGNiTT'
}

const helmetBuyReport = {
    title: 'Purchase Report | MAGNiTT',
    metaContent: 'Empower your team with data-driven research and insights on the tech ecosystem, as referenced by TechCrunch, Bloomberg and the FT',
    ogTitle: 'Purchase Report'
}

// set routes

const routes = [
    {
        path: '/',
        exact: true,
        component: Home,
    },
    {
        path: '/startups',
        exact: true,
        component: Startup,
    },
    {
        path: '/client-directory',
        exact: true,
        component: ClientDirectory,
    },
    {
        path: '/en-:iso/startups',
        exact: true,
        component: Startup,
    },
    {
        path: '/en-:iso/startups/:industry',
        exact: true,
        component: Startup,
    },
    /*{
        path: '/investors',
        exact: true,
        component: Investor,
    },*/
    {
        path: '/investors',
        exact: true,
        component: Ecosystem,
    },
    {
        path: '/en-:iso/investors',
        exact: true,
        component: Ecosystem,
    },
    {
        path: '/en-:iso/investors/:type',
        exact: true,
        component: Ecosystem,
    },
    {
        path: '/dashboards',
        exact: true,
        component: SavedSearchDashBoard,
    },
    {
        path: '/people',
        exact: true,
        component: People
    },
    {
        path: '/myfavourite',
        exact: true,
        component: Follow
    },
    {
        path: '/newsletters',
        exact: true,
        component: Newsletters,
        fetchInitialData: () => api.getPastNewsletters()
    },
    {
        path: '/preferences',
        exact: true,
        component: Preferences
    },
    {
        path: '/jobs',
        exact: true,
        component: Jobs
    },
    {
        path: '/internships',
        exact: true,
        component: Internships
    },
    {
        path: '/pricing',
        exact: true,
        component: PricingPage
    },

    // payment pages
    {
        path: '/subscribe/:planID/:planName',
        component: BuyMagnitt,
        pageType: "subscribe",
        // ssr helmet data
        helmet: helmetSubscribe
    },
    {
        path: '/buy/:slug',
        exact: true,
        component: BuyMagnitt,
        pageType: "getReport",
        // ssr helmet data
        helmet: helmetBuyReport
    },
    // end payment pages

    {
        path: '/faq',
        exact: true,
        component: FAQ
    },
    {
        path: '/mysettings',
        exact: true,
        component: AccountSettings
    },
    {
        path: '/mysettings/cards',
        exact: true,
        component: ManageCard
    },
    {
        path: '/mysettings/payment-history',
        exact: true,
        component: PaymentHistory
    },
    {
        path: '/verify-address',
        exact: true,
        component: VerifyAddress
    },
    {
        path: '/aboutus',
        exact: true,
        component: AboutUs
    },
    {
        path: '/terms-and-conditions',
        exact: true,
        component: TermsofService
    },
    {
        path: '/analyst-hours-terms',
        exact: true,
        component: AnalystHoursUsagePolicy
    },
    {
        path: '/research-terms',
        exact: true,
        component: TermsofResearch
    },
    {
        path: '/privacy-policy',
        exact: true,
        component: PrivacyandCookies
    },
    {
        path: '/contactus',
        exact: true,
        component: ContactUs
    },
    {
        path: '/people/:code-:id',
        exact: true,
        component: SinglePerson,
        fetchInitialData: (path, token, refreshToken) => api.fetchPersonDetails(path.slice(path.lastIndexOf('-') + 1), token, refreshToken)
    },
    {
        path: '/people/:id/:code',
        exact: true,
        component: SingleUser,
        fetchInitialData: (path, token, refreshToken) => api.fetchUserDetails(path.split('/')[2], token, refreshToken)
    },
    {
        path: '/jobs/:slug',
        exact: true,
        component: SingleJob,
        fetchInitialData: (path, token, refreshToken) => api.fetchJobDetails(path.split('/')[2], token, refreshToken)
    },
    {
        path: '/managejobs',
        exact: true,
        component: ManageJobs
    },
    {
        path: '/managecompany',
        exact: true,
        component: ManageCompany
    },

    // onboarding
    {
        path: '/onboarding/:id/:code',
        exact: true,
        component: CreateEditSICE,
        fetchInitialData: (path, token, refreshToken) => api.fetchUserDetails(path.split('/')[2], token, refreshToken),
        isEditPage: true,
        entityType: "user",
        isOnboarding: true
    },

    // manage user
    {
        path: '/people/:id/:code/manage',
        exact: true,
        component: CreateEditSICE,
        fetchInitialData: (path, token, refreshToken) => api.fetchUserDetails(path.split('/')[2], token, refreshToken),
        isEditPage: true,
        entityType: "user",
    },

    // CreateEditSICE - startup
    // note: the path '/entity/add' must be before '/entity/:slug'
    // otherwise 'add' gets treated as a slug

    {
        path: '/startups/add',
        exact: true,
        component: CreateEditSICE,
        isAddPage: true,
        entityType: "startup"
    },
    {
        path: '/startups/:slug',
        exact: true,
        component: StartupPage,
        fetchInitialData: (path, token, refreshToken) => api.fetchStartupDetails(path.split('/')[2], token, refreshToken)
    },
    {
        path: '/startups/:slug/edit',
        exact: true,
        component: CreateEditSICE,
        fetchInitialData: (path, token, refreshToken) => api.fetchStartupDetails(path.split('/')[2], token, refreshToken),
        isEditPage: true,
        entityType: "startup"
    },
    {
        path: '/startups/:slug/claim',
        exact: true,
        component: CreateEditSICE,
        fetchInitialData: (path, token, refreshToken) => api.fetchStartupDetails(path.split('/')[2], token, refreshToken),
        isClaimPage: true,
        entityType: "startup"
    },

    // CreateEditSICE - investor
    // note: the path '/entity/add' must be before '/entity/:slug'
    // otherwise 'add' gets treated as a slug

    {
        path: '/investors/add',
        exact: true,
        component: CreateEditSICE,
        isAddPage: true,
        entityType: "investor"
    },
    {
        path: '/investors/:slug',
        exact: true,
        entityType: "investor",
        component: ICEPage,
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "investor";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },

    {
        path: '/investors/:slug/edit',
        exact: true,
        component: CreateEditSICE,
        isEditPage: true,
        entityType: "investor",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "investor";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },
    {
        path: '/investors/:slug/claim',
        exact: true,
        component: CreateEditSICE,
        isClaimPage: true,
        entityType: "investor",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "investor";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },

    // CreateEditSICE - corporate
    // note: the path '/entity/add' must be before '/entity/:slug'
    // otherwise 'add' gets treated as a slug

    {
        path: '/corporates/add',
        exact: true,
        component: CreateEditSICE,
        isAddPage: true,
        entityType: "corporate"
    },
    {
        path: '/corporates/:slug',
        exact: true,
        entityType: "corporate",
        component: ICEPage,
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "corporate";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },
    {
        path: '/corporates/:slug/edit',
        exact: true,
        component: CreateEditSICE,
        isEditPage: true,
        entityType: "corporate",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "corporate";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },
    {
        path: '/corporates/:slug/claim',
        exact: true,
        component: CreateEditSICE,
        isClaimPage: true,
        entityType: "corporate",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "corporate";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },

    // CreateEditSICE - enabler
    // note: the path '/entity/add' must be before '/entity/:slug'
    // otherwise 'add' gets treated as a slug

    {
        path: '/enablers/add',
        exact: true,
        component: CreateEditSICE,
        isAddPage: true,
        entityType: "enabler"
    },
    {
        path: '/enablers/:slug',
        exact: true,
        entityType: "enabler",
        component: ICEPage,
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "enabler";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },
    {
        path: '/enablers/:slug/edit',
        exact: true,
        component: CreateEditSICE,
        isEditPage: true,
        entityType: "enabler",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "enabler";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },
    {
        path: '/enablers/:slug/claim',
        exact: true,
        component: CreateEditSICE,
        isClaimPage: true,
        entityType: "enabler",
        fetchInitialData: async (path, token, refreshToken) => {
            const slug = path.split('/')[2];
            const entityType = "enabler";
            return await api.fetchICEDetails(slug, entityType, token, refreshToken);
        }
    },

    //

    {
        path: '/jobs/:slug/edit',
        exact: true,
        component: EditJob
    },
    {
        path: '/jobs/:slug/manage',
        exact: true,
        component: ManageSingleJob
    },
    {
        path: '/loginpage',
        exact: true,
        component: LoginPage
    },
    {
        path: '/payment/success',
        component: PaymentSuccess
    },
    {
        path: '/download-success',
        component: DownloadSuccess
    },
    {
        path: '/verify-email-success',
        component: VerifyEmailSuccess
    },
    {
        path: '/marketing',
        exact: true,
        component: Marketing,
    },
    {
        path: '/notifications',
        exact: true,
        component: NotificationsPage,
    },
    {
        path: '/not-found',
        exact: true,
        component: NoMatch,
    },
    {
        path: '/feature-unavailable',
        exact: true,
        component: FeatureUnavailable,
    },
    {
        path: '/contact-sales',
        exact: true,
        component: ContactSalesForm
    },
    {
        path: '/bespoke-reports',
        exact: true,
        component: BespokeReportsForm
    },
    {
        path: '/funds',
        exact: true,
        component: LimitedPartnersTracker
    },
    {
        path: '/africa-newsletter',
        exact: true,
        component: AfricaNewsletterForm
    },
    {
        path: '/newsletter-redirect',
        exact: true,
        component: RedirectionPage
    },
    {
        path: '/newsletter-success',
        exact: true,
        component: ThankYouPage
    },
    {
        path: '/demo-request',
        exact: true,
        component: DemoRequestForm
    },

    /* market maps */
    {
        path: '/lists/united-arab-emirates-uae',
        exact: true,
        component: MarketMapUAE
    },
    {
        path: '/map/leap-2023',
        exact: true,
        component: MarketMapLEAP
    },
    {
        path: '/map/northstar-2023',
        exact: true,
        component: MarketMapNorthStar
    },
    {
        path: '/lists/meapt',
        exact: true,
        component: DefaultMarketMap
    },
    /* end -- market maps */

    // Feature Unavailable
    {
        path: '/apply/:applicationType',
        component: FeatureUnavailable
    },
    {
        path: '/applications',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/investors/:id/:code',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/:applicationType/add',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/:applicationType/:id/:code/apply',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/:applicationType/:id/:code/manage',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/:applicationType/:id/:code/submission/:sId',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/application/:applicationType/:appId/:code/edit',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/manageapplication',
        exact: true,
        component: FeatureUnavailable
    },
    {
        path: '/connections/:type?/:nid?',
        component: FeatureUnavailable
    },

    /* advanced analytics */

    {
        path: '/advanced-analytics',
        exact: true,
        component: AdvancedAnalyticsDashboard,
    },
    {
        path: '/analytics/customized-charts',
        component: CustomAnalyticsPage
    },
    {
        path: '/analytics/market-sizing',
        component: MarketSizingPage
    },
    {
        path: '/analytics/startup-analysis',
        component: StartupAnalyticsPage
    },
    {
        path: '/analytics/custom-charts',
        component: CustomDashboardPage
    },
    {
        path: '/analytics/deal-size',
        component: DealSizeAnalysisPage
    },
    {
        path: '/analytics/geography',
        component: GeoBenchmarkPage
    },
    {
        path: '/analytics/industry',
        component: IndustryBenchmarkPage
    },
    {
        path: '/analytics/investor-ranking',
        component: InvestorRankingPage
    },
    {
        path: '/analytics/exits',
        component: ExitAnalysisPage
    },

    /* custom market maps */

    {
        path: '/market-maps',
        exact: true,
        component: CustomMarketMaps
    },
    {
        path: '/market-maps/add',
        component: AddEditCustomMap,
        isAddPage: true
    },
    {
        path: '/market-maps/:id/edit',
        component: AddEditCustomMap,
        isEditPage: true
    },
    {
        path: '/market-maps/:id',
        component: ViewCustomMap,
    },

    /* Private Equity */
    {
        path: '/private-equity',
        component: PrivateEquity,
    }

]

export default routes
