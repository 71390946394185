import { sharedEndpoints } from 'shared-helpers'

const API_URL_NEW = sharedEndpoints.API_URL_NEW;
const SSR_API_URL_NEW = sharedEndpoints.SSR_API_URL_NEW;
const WEB_URL = sharedEndpoints.WEB_URL;
const SITE_IMAGE_URL = sharedEndpoints.SITE_IMAGE_URL;
const PAYFORT_API = sharedEndpoints.PAYFORT_API;
const PAYFORT_SIGN = sharedEndpoints.PAYFORT_SIGN;
const PAYFORT_ACCESS = sharedEndpoints.PAYFORT_ACCESS;
const PAYFORT_MERCH = sharedEndpoints.PAYFORT_MERCH;
const PAYFORT_URL = sharedEndpoints.PAYFORT_URL;
const LOGGER_URL = sharedEndpoints.LOGGER_URL;
const SHARED_WEB_URL = sharedEndpoints.SHARED_WEB_URL;
const TABLEAU_URL = sharedEndpoints.TABLEAU_URL;
const BIGQUERY_URL = sharedEndpoints.BIGQUERY_URL;
const PUBLIC_IMG_URL = sharedEndpoints.PUBLIC_IMG_URL;
const IMG_PATH = sharedEndpoints.SHARED_WEB_URL;
const PUBLIC_VIDEO_URL = sharedEndpoints.PUBLIC_VIDEO_URL;

// dev test
// const SHARED_WEB_URL  = "http://localhost:4000";
// const API_URL_NEW     = "https://api.magnitt.com";
// const INVAPP_API_URL  = 'https://invapi.magnitt.com';
// const API_URL_NEW     = 'http://localhost:3212/core-api';
// const SSR_API_URL_NEW = 'http://localhost:3212/core-api';

export {
    API_URL_NEW,
    SSR_API_URL_NEW,
    WEB_URL,
    SHARED_WEB_URL,
    SITE_IMAGE_URL,
    PAYFORT_API,
    PAYFORT_SIGN,
    PAYFORT_ACCESS,
    PAYFORT_MERCH,
    PAYFORT_URL,
    LOGGER_URL,
    TABLEAU_URL,
    PUBLIC_IMG_URL,
    IMG_PATH,
    PUBLIC_VIDEO_URL,
    BIGQUERY_URL
}
