import React, { Component } from 'react';
import { SHARED_WEB_URL } from './lib/endpoints';



class MicroFrontend extends Component {

    componentDidMount() {
        this.handleMountComponent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refresh !== this.props.refresh) {
            this.handleUnmountComponent();
            this.handleMountComponent();
        }
    }

    componentWillUnmount() {
        this.handleUnmountComponent();
    }

    handleMountComponent = () => {
        const { name } = this.props;
        const jsScriptId = `micro-frontend-script-${name}`;

        if (document.getElementById(jsScriptId)) {
            this.renderMicroFrontend(jsScriptId);
            return;
        }

        const script = document.createElement('script');
        script.id = jsScriptId;
        script.src = `${SHARED_WEB_URL}/bundle.js`;
        script.onload = () => this.renderMicroFrontend(jsScriptId);
        document.head.appendChild(script);
    }

    handleUnmountComponent = () => {
        const { name } = this.props;
        let containerId = `${name}-container`;
        window[`unmountMicroFE`] && window[`unmountMicroFE`](containerId);
    }

    renderMicroFrontend = (jsScriptId) => {
        const { name, history, data } = this.props;
        let containerId = `${name}-container`;
        window[`renderMicroFE`] && window[`renderMicroFE`](name, containerId, history, data);

        // remove the script after rendering to prevent bundle duplication
        if (jsScriptId && document.getElementById(jsScriptId)) {
            document.getElementById(jsScriptId).remove();
        }
    };

    render() {
        return <main id={`${this.props.name}-container`} />;
    }
}

export default MicroFrontend;