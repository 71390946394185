import React, { Component } from 'react';

// market map elements
import StartupMarketMapLandscape from './StartupMarketMapLandscape';
import StartupMarketMapCharts from './StartupMarketMapCharts';
import StartupMarketMapTable from './StartupMarketMapTable';
import InvestorMarketMapLandscape from './InvestorMarketMapLandscape';
import InvestorMarketMapTable from './InvestorMarketMapTable';

// reusable
import MagnittLoader from '/src/micro-frontends/MagnittLoader';

// constants and helpers
import { SAVED_TEMPLATE_TYPES } from '/src/lib/constants';
import SegmentHelperClass from '/src/lib/segmentHelper';
import { sortingOptions } from '../MarketMapFormFields';
import * as CustomMarketMapHelpers from '../../helpers/MarketMapHelpers'

const SegmentHelper = new SegmentHelperClass();



class MarketMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            isLandscape: true,
            isDashboard: false,
            isTable: false,
            showLoading: true,
            mapType: this.props.state.mapType,

            directoryCharts: {
                limit: 10,
                refresh: 0
            },
            directoryLandscape: {
                limit: 9,
                marketMapData: {
                    data: []
                },
                refresh: 0
            }
        };
    }

    componentDidMount() {
        this.getMapData();
    }

    getMapData = () => {
        const { view } = this.props;
        const passedState = this.props.state;
        const { mapType } = passedState;

        this.group = passedState.mapGrouping;

        let sortingOps = [];
        if (mapType === "startup_market_map") {
            sortingOps = sortingOptions.startup;
        } else {
            sortingOps = sortingOptions.investor;
        }

        this.sort = sortingOps[passedState.mapSortIndex.value - 1];

        let filterArray = [];

        if (view === 'preview') {
            filterArray = {
                country: passedState.countryArr,
                region: passedState.regionArr,
                industry: passedState.focusArr
            }
            this.filter = CustomMarketMapHelpers.prepareMapFilters(filterArray, mapType);
        } else if (view === "finalMap") {
            this.filter = passedState.filters;
        }

        this.updateMapFilters();
    }

    updateMapFilters = () => {
        let { filter } = this;

        if (!filter) {
            filter = [];
        }

        // Remove region filter from api call
        filter = filter.filter(item => item.key !== 'region');

        this.setState(prevState => ({
            directoryCharts: {
                ...prevState.directoryCharts,
                filter
            },
            directoryLandscape: {
                ...prevState.directoryLandscape,
                filter
            },
            showLoading: false
        }));
    }

    gtag = (label) => {
        window.gtag('event', 'click', {
            'event_category': `analytics`,
            'event_label': label
        })
    };

    changeTab(currentTab) {
        this.gtag(currentTab.replace("is", ''));
        let obj = {};
        obj.isLandscape = false;
        obj.isDashboard = false;
        obj.isTable = false;
        obj[currentTab] = true;
        this.setState(obj);

        CustomMarketMapHelpers.handleRemoveTooltip();
    }

    renderLandscape = () => {
        const { isLandscape, directoryLandscape, mapType } = this.state;
        const { group, sort } = this;
        const { view } = this.props;

        // add display-none class to prevent componentDidMount API calls from running multiple times
        return (
            <div className={`${isLandscape ? "" : "d-none"}`}>
                {mapType === SAVED_TEMPLATE_TYPES.marketMapStartup ?
                    <StartupMarketMapLandscape
                        data={directoryLandscape}
                        view={view}
                        group={group}
                        order={sort}
                        tabIsVisible={isLandscape}
                    />
                    :
                    <InvestorMarketMapLandscape
                        data={directoryLandscape}
                        view={view}
                        group={group}
                        order={sort}
                        tabIsVisible={isLandscape}
                    />
                }
            </div>
        )
    }

    renderDashboard = () => {
        const { isDashboard, directoryCharts } = this.state;

        // add display-none class to prevent componentDidMount API calls from running multiple times
        return (
            <div className={`${isDashboard ? "" : "d-none"}`}>
                <StartupMarketMapCharts
                    data={directoryCharts}
                    tabIsVisible={isDashboard}
                />
            </div>
        )
    }

    renderTable = () => {
        const { isTable, mapType } = this.state;
        const { view, user } = this.props;
        let { filter, sort } = this;

        if (!filter) {
            filter = [];
        }

        // add display-none class to prevent componentDidMount API calls from running multiple times
        return (
            <div className={`${isTable ? "" : "d-none"}`}>
                {mapType === SAVED_TEMPLATE_TYPES.marketMapStartup ?
                    <StartupMarketMapTable
                        view={view}
                        order={sort}
                        mapFilters={filter}
                        user={user}
                        tabIsVisible={isTable}
                    />
                    :
                    <InvestorMarketMapTable
                        view={view}
                        order={sort}
                        mapFilters={filter}
                        user={user}
                        tabIsVisible={isTable}
                    />
                }
            </div>
        )
    }

    renderTabs() {
        return (
            <div className="navigation-wrapper">
                <div onClick={() => { this.changeTab('isLandscape'); this.segmentTrack('Landscape') }} className={this.state.isLandscape ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-th-large"></i>
                    Landscape
                </div>
                {this.state.mapType === SAVED_TEMPLATE_TYPES.marketMapStartup &&
                    <div onClick={() => { this.changeTab('isDashboard'); this.segmentTrack('Trends') }} className={`hidden-md-down ${this.state.isDashboard ? 'activeTab' : 'nonActiveTab'}`}>
                        <i className="fa fa-chart-line"></i>
                        Trends
                    </div>
                }
                <div onClick={() => { this.changeTab('isTable'); this.segmentTrack('Table') }}
                    className={this.state.isTable ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-table"></i>
                    Table
                </div>
            </div>
        );
    }

    segmentTrack(action) {
        SegmentHelper.trackEvent(action);
    }

    Login(event) {
        document.getElementById('headerLogin').click();
    }

    Register(event) {
        document.getElementById('headerRegister').click();
    }

    render() {
        return (
            <div>
                <section className='content-box network-map-main preview-market-map'>
                    {this.state.showLoading &&
                        <MagnittLoader data={{ height: "300px", background: "transparent" }} />
                    }
                    {!this.state.showLoading &&
                        <div>
                            <div className="network-map-navigation">
                                {this.renderTabs()}
                            </div>
                            {this.renderLandscape()}
                            {this.state.mapType === SAVED_TEMPLATE_TYPES.marketMapStartup &&
                                this.renderDashboard()
                            }
                            {this.renderTable()}
                        </div>
                    }
                </section>
            </div>
        )
    }
}

export default (MarketMap);
