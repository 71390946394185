export const EMAIL_UPDATED_SUCCESS = 'Email updated successfully!'
export const SOMETHING_WENT_WRONG = 'Something went wrong, please try again or contact our team if the error persists'
export const PASSWORD_CHANGE_SUCCESS = 'Password successfully updated!'
export const SUBSCRIPTION_CANCELLED = 'Subscription successfully cancelled!'
export const EMAIL_PREF_UPDATED_SUCCESS = 'Email preferences updated successfully!'
export const CARD_DELETED_SUCCESS = "Card has been removed successfully!"
export const CARD_ADDED_SUCCESS = "Card has been added successfully!"
export const SAVE_CARD_GENERAL_MSG = "Something went wrong while adding the card, please try again!"
export const SET_PRIMARY_CARD_SUCCESS = "Primary card has been set successfully!"
export const FUNDING_ROUND_UPDATE_REQUEST_MESSAGE = "The funding round update has been sent to our team to verify and update accordingly.";
export const FUNDING_ROUND_DELETE_REQUEST_MESSAGE = "The funding round delete has been sent to our team to verify and act accordingly.";
export const SALES_CONTACT_SUCCESS_MESSAGE = "Thank you for contacting us, we will get back to you shortly.";
export const UPDATE_SUCCESS_MESSAGE = "Successfully Updated!";
export const DELETE_SUCCESS_MESSAGE = "Successfully Deleted!";
// dashboard
export const SEARCH_SAVE_SUCCESS = "Successfully saved! You can view it in saved searches in your dashboard";
export const CREATED_DASHBOARD_SUCCESS = "Successfully created a dashboard! You can view it in your dashboards ";
export const UPDATE_DASHBOARD_SUCCESS = "Your dashboard has been successfully updated! You can view it in your dashboards.";
export const UPDATED_DIRECTORY_DASHBOARD_SUCCESS = "You have successfully updated your dashboard";
export const NAME_ALREADY_EXISTS = "Name already exists, please choose a different name";
// chart data - no data for a single chart
export const NO_CHART_DATA_SINGLE = 'Data is not available for this chart within the current filters';

// chart data - no data for all charts on a page
export const NO_DATA = 'There is not enough data to display this section, as your selected filters are too narrow'
export const NO_CHART_DATA = 'There is not enough data to display the charts, as your selected filters are too narrow';

// dynamic startup benchmarking tool
export const STARTUP_EXISTS = "Startup already part of the table"
export const STARTUP_ADDED = "Startup added to the table"
