import axios from 'axios';

// constants
import { API_URL_NEW } from '../lib/endpoints'

// helpers
import { prepareSelectParam } from '../lib/queryParams';

// common functions for all entities

const entityApi = {

    getUserTeamStatus: (entityId, entityType) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/team-membership-status`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getRelatedNews: (entityId, entityType) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/news`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getCompanyEmail: (entityId, entityType) => {
        const fields = prepareSelectParam(["email_relations"]);

        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getEntityName: (entityId = null, entityType = null) => {
        const fields = prepareSelectParam(["name"]);

        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getEntityDetails: (entityId, entityType,fieldsArr) => {
        const fields = prepareSelectParam(fieldsArr);
        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                const resp = response.data.data;
                if (response.data.status == 200 && resp.id) {
                    resolve(resp);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export default entityApi;
