import isEmpty from 'lodash/isEmpty';

// api
import taxonomyApi from '/src/apis/taxonomyApi';
import { customMarketMapApi } from '/src/apis/customMarketMapApi';

// constants
import { ALLOWED_QUERY_OPERATOR, SAVED_TEMPLATE_TYPES } from '/src/lib/constants';
import { groupingOptions, sortingOptions } from '../components/MarketMapFormFields';

// helpers
import * as filterHelper from '/src/lib/filterHelper';
import { prepareFilterParam, prepareSortParam } from '/src/lib/queryParams';



/*
    method to get locations list for custom market map form
    when region is selected => select countries in that region
    when region is de-selected => de-select countries in that region
*/
export const getRegionLocations = async (state, operationType, region_id) => {
    let result;
    if (operationType === "add") {
        result = await addLocationsFromRegion(state, region_id);
    } else if (operationType === "remove") {
        result = handleRemoveCountry(state)
    }
    return result;
}

/* remove countries from a de-selected region */
const handleRemoveCountry = (state) => {

    let { locationsInRegion, regionArr, countryArr } = state;
    let selectedRegions = regionArr.map(region => {
        if (region.isDeleted) {
            return;
        }

        if (region.id) {
            return region.id;
        } else if (region.itemId) {
            return region.itemId;
        }
    })

    let tempLocation = [];
    let temp = locationsInRegion && locationsInRegion.map((loc, i) => {
        if (!selectedRegions.includes(loc.region_id)) {
            const idsToRemove = loc.locs.map(location => {
                return location.id;
            })
            countryArr.map(item => {
                if (!idsToRemove.includes(item.id)) {
                    tempLocation.push(item);
                }
            })
        } else {
            return loc;
        }
    })

    temp = temp && temp.filter(item => item); // remove undef
    countryArr = tempLocation;

    return {
        locationsInRegion: temp, countryArr
    };

}

const addLocationsFromRegion = async (state, region_id) => {

    const regionFilter = {
        key: 'region',
        value: [region_id],
        op: ALLOWED_QUERY_OPERATOR.equal
    };

    return await taxonomyApi.getLocations(300, regionFilter).then(
        response => {

            // selected locations: ones that match region filter + ones previously selected
            let { countryArr, locationsInRegion } = state;
            countryArr = [...response, ...countryArr];

            // remove duplicates
            countryArr.map(function (item) {
                if (!item.id && item.itemId) {
                    item.id = item.itemId;
                }
            })
            countryArr = countryArr.filter(function ({ id }) {
                return !this[id] && (this[id] = id)
            }, {})

            // save the locs in this region, so it can be removed when region is deselected
            locationsInRegion = (locationsInRegion || []);
            locationsInRegion.push({
                locs: response, region_id
            })

            return ({
                countryArr,
                locationsInRegion
            })

        }
    )

}

// prepare map filters for API payload
export const prepareMapFilters = (filters, mapType) => {
    let filterTypes = ['country', 'industry', 'region'];
    let filterArray = [];
    filterTypes.map(item => {

        if (filters[item] && filters[item].length > 0) {

            let key = null;
            if (item === 'industry') {
                key = (mapType === SAVED_TEMPLATE_TYPES.marketMapStartup) ? 'primary_industry_filter' : 'industry';
            } else if (item === 'region') {
                key = 'region';
            } else if (item === 'country') {
                key = 'hq_id';
            }

            let filterVal = [];
            filters[item].map(itemVal => {
                if (itemVal.isDeleted) {
                    return;
                }
                filterVal.push(itemVal.itemId);
            });

            if (key && filterVal.length > 0) {
                filterArray.push(
                    {
                        key: key,
                        value: filterVal,
                        op: ALLOWED_QUERY_OPERATOR.equal
                    }
                )
            }

        }
    });
    return filterArray;
}

// decode map filters from string
export const decodeMapFilters = (string, stateObj) => {

    const { countryList, regionsList, industryFocus } = stateObj;
    const list = {
        country: countryList,
        region: regionsList,
        industry: industryFocus
    }

    let filters = {
        country: [], region: [], industry: []
    }

    const decoded = filterHelper.decodeFilters(string);
    decoded.map(dec => {
        const keys = ['hq_id', 'region', 'primary_industry_filter', 'industry'];
        keys.map(key => {
            if (dec[key] && dec[key].length > 0) {

                let filterCode = '';
                if (key === 'hq_id') {
                    filterCode = 'country';
                } else if (key === 'region') {
                    filterCode = 'region';
                } else if (key === 'primary_industry_filter' || key === 'industry') {
                    filterCode = 'industry';
                }

                list[filterCode] && list[filterCode].map(listItem => {
                    let id = listItem.itemId ? listItem.itemId : listItem.id;
                    id = id.toString();
                    if (dec[key].includes(id)) {
                        filters[filterCode].push(listItem)
                    }
                })

            }
        })
    });

    return filters;

}

export const getMapData = (map_id, decodeSavedFilters, userId) => {
    return new Promise((resolve, reject) => {
        customMarketMapApi.getMapByID(map_id, userId).then(async (res) => {

            if (!isEmpty(res)) {
                let obj = {
                    marketMapName: res.name,
                    mapOwner: res.user_id,
                    mapGrouping: [],
                    mapType: res.entity_type
                };

                if (res.filters) {
                    decodeSavedFilters(res.filters);
                }

                if (res.group_by) {
                    let groupingOps = [];
                    if (res.entity_type === SAVED_TEMPLATE_TYPES.marketMapStartup) {
                        groupingOps = groupingOptions.startup;
                    } else {
                        groupingOps = groupingOptions.investor;
                    }

                    // remove any options that aren't in the options list (mostly invalid test data)
                    groupingOps.map(option => {
                        if (res.group_by.includes(option.name)) {
                            obj.mapGrouping.push(option);
                        }
                    })
                }

                let sortingOps = [];
                if (res.entity_type === SAVED_TEMPLATE_TYPES.marketMapStartup) {
                    sortingOps = sortingOptions.startup;
                } else {
                    sortingOps = sortingOptions.investor;
                }

                sortingOps.map(sort => {
                    if (res.options.includes(sort.field) && res.options.includes(sort.order)) {
                        obj.mapSorting = [{ key: sort.field, sortVal: sort.order }];
                        obj.mapSortIndex = { label: sort.name, value: sort.id }; // to show selected val in input
                    }
                })

                resolve(obj);
            } else {
                reject()
            }
        }).catch(err => reject(err))
    })
}

// format user's selected fields/filters into format expected by API
export const prepareAPIFields = (data) => {

    // 'group by' field
    let groupBy = [];
    data.groupBy.map(item => {
        if (!item.isDeleted) {
            groupBy.push(item.name);
        }
    })

    const payload = {
        user_id: data.userId,
        options: prepareSortParam(data.sortBy),
        filters: prepareFilterParam(data.filter),
        entity_type: data.mapType,
        is_default: 0,
        name: data.name,
        group_by: groupBy
    }
    return payload;

}

export const handleRemoveTooltip = () => {
    // remove tooltips as it interferes with position of tooltips in new tab
    $(".d3-tooltip").remove();
    //remove landscape tooltips when tab is changed
    $(".popover").remove();
}