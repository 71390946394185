import { VALUE_SEPARATOR, FIELD_SEPARATOR, EQUAL_SYMBOL, ALLOWED_QUERY_OPERATOR } from './constants';

/**
 * Method to find and return the filter values from the filter array
 * 
 * @param {Array}   filterArr A decoded filter array
 * @param {String}  key Key (filter name) to search  
 * @returns {Object}   Returns an object with key and value
 */
export const getFilterByKey = (filterArr, key, stateArray, skipIds) => {
    let obj = filterArr.find(item => item.hasOwnProperty(key))

    if (skipIds) {
        return (obj) ? obj[key] : undefined
    }
    let ids = (obj) ? obj[key] : []
    return stateArray.filter(itm => ids.findIndex(ind => ind == itm.id) >= 0)
}

/**
 * Method to find and return min and max values from the filter array as an object
 * 
 * @param {Array}   filterArr A decoded filter array
 * @param {String}  key Key (filter name) to search  
 * @returns {Object}   Returns an object with min and max values
 */

export const getRangeFilterByKey = (filterArr, key) => {
    let minVal, maxVal, btwVal;
    btwVal = filterArr.find(item => item.hasOwnProperty(key) && (item.operator == 'btw:'));

    if (btwVal) {
        minVal = btwVal[key].split(`${VALUE_SEPARATOR}`)[0];
        maxVal = btwVal[key].split(`${VALUE_SEPARATOR}`)[1];
    } else {
        minVal = filterArr.find(item => item.hasOwnProperty(key) && (item.operator == '>' || item.operator == '>='));
        maxVal = filterArr.find(item => item.hasOwnProperty(key) && (item.operator == '<' || item.operator == '<='));
    }

    if (!minVal && !maxVal) {
        return '';
    }

    return { min: minVal, max: maxVal };
}

/**
 * Method to convert a string containing encoded filters to a filter object
 * 
 * @param {String}   string (encoded)  
 * @example 'owned_by%5E%3E%5E0%5E%2Cbusiness_type%5E%3A4843' 
 * @returns {String}   Returns a plain object with key(s):value(s)
 */

export const decodeFilters = (savedFilters) => {
    return decodeURIComponent(savedFilters).split(FIELD_SEPARATOR).map(item => {
        
        const equalSymbol = ALLOWED_QUERY_OPERATOR.equal;
        const substringSymbol = ALLOWED_QUERY_OPERATOR.substring;
        const gtSymbol = ALLOWED_QUERY_OPERATOR.gt;
        const gteSymbol = ALLOWED_QUERY_OPERATOR.gte;
        const ltSymbol = ALLOWED_QUERY_OPERATOR.lt;
        const lteSymbol = ALLOWED_QUERY_OPERATOR.lte;
        const btwSymbol = ALLOWED_QUERY_OPERATOR.btw;
        const keywordSymbol = ALLOWED_QUERY_OPERATOR.keyword;
        let value = ''; 
        let obj = ''; 
        let key = ''; 
        let gtSplit = ''; 
        let gtEqSplit = ''; 
        let ltSplit = '';
        let ltEqSplit = '';
        let btwSplit = '';
        let operator = '=';
        
        if (item.includes(equalSymbol)) {
            obj = item.split(equalSymbol);
        } else if (item.includes(substringSymbol)) {
            obj = item.split(substringSymbol);
            operator = substringSymbol;
        } else if (item.includes(keywordSymbol)) {
            obj = item.split(keywordSymbol);
            operator = keywordSymbol;
        } else {
            obj = [item];
        }
        
        key = (obj && obj.length > 0) ? obj[0] : 'default';

        if (key.includes(gtSymbol)) {
            gtSplit = key.split(gtSymbol);

        } else if (key.includes(gteSymbol)) {
            gtEqSplit = key.split(gteSymbol);

        } else if (key.includes(ltSymbol)) {
            ltSplit = key.split(ltSymbol);

        } else if (key.includes(lteSymbol)) {
            ltEqSplit = key.split(lteSymbol);
        } else if (key.includes(btwSymbol)) {
            btwSplit = key.split(btwSymbol);
        }

        if (gtSplit.length > 1) {
            key = gtSplit[0]
            value = gtSplit[1]
            operator = '>'
        } else if (gtEqSplit.length > 1) {
            key = gtEqSplit[0]
            value = gtEqSplit[1]
            operator = '>='
        } else if (ltSplit.length > 1) {
            key = ltSplit[0]
            value = ltSplit[1]
            operator = '<'
        } else if (ltEqSplit.length > 1) {
            key = ltEqSplit[0]
            value = ltEqSplit[1]
            operator = '<='
        } else if (btwSplit.length > 1) {
            key = btwSplit[0]
            value = btwSplit[1]
            operator = 'btw:'
        } else {
            value = obj[1].split(VALUE_SEPARATOR)
        }

        return {
            [key]: value,
            operator: operator
        }
    })
}

/**
 * Method to append (K/M/B) to numbers according to value 
 * 
 * @param {int}   Integer number to format
 * @returns {String}   Returns a formatted string
 */

export const formatValue = (value) => {
    let maxDisplay = ''
    if (value >= 1000000000) {
        maxDisplay = '$' + (Math.round(value / 100000000, -1) / 10) + 'B';
    } else if (value >= 1000000) {
        maxDisplay = '$' + (Math.round(value / 100000, -1) / 10) + 'M';
    } else if (value >= 1000) {
        maxDisplay = '$' + (Math.round(value / 100, -1) / 10) + 'K';
    } else {
        maxDisplay = '$' + value
    }

    return maxDisplay
}

/**
 * Method to cnvert filter string to url 
 * 
 * @param {String}   String filter string
 * @returns {String}   Returns the filter url
 */

export const convertToUrl = (savedSearch) => {
    let dFilter = decodeFilters(savedSearch)

    let url = '?'
    let op = ''
    let filterLength = dFilter.length
    for (let i in dFilter) {
        let keyName = Object.keys(dFilter[i])[0]
        let value = (Array.isArray(dFilter[i][keyName])) ? dFilter[i][keyName].join(',') : dFilter[i][keyName]

        const operator = dFilter[i].operator;
        if (operator == ALLOWED_QUERY_OPERATOR.substring || operator == ALLOWED_QUERY_OPERATOR.keyword) {
            op = operator;
            url += keyName + op + value;
        }
        else if (dFilter[i].operator != '=') {
            op = '^' + dFilter[i].operator;
            url += keyName + '=' + op + value;
        } else {
            op = '';
            url += keyName + '=' + op + value;
        }

        url += (i < (filterLength - 1)) ? '&' : ''
    }

    return url
}

/**
* Method to cnvert url string to filter array 
* 
* @param {String}   String url string
* @returns {String}   Returns the filter array
*/

export const convertToFilterString = (filterString) => {
    let urlFilterString = decodeURIComponent(filterString).split('?')

    let filters = (urlFilterString[1]) ? urlFilterString[1] : null
    let filtersArray = []

    if (filters) {

        filters = filters.split('&');

        // when trying to save a filter keyword with the '&' character, the value is not decoded properly
        //because the '&' character is interpreted as a separator for the filter query
        //so we replace it with '(amp)' and then decode it back to '&'
        filters = filters.map(item => item.includes('(amp)') ? item.replaceAll('(amp)', '&') : item);

        let key = '';
        let splitString = '';
        let values = [];
        let symbol = '';
        const substringSymbol = ALLOWED_QUERY_OPERATOR.substring;
        const keywordSymbol = ALLOWED_QUERY_OPERATOR.keyword;

        for (let i in filters) {
            // Ignore old filters that are not in use
            if (filters[i].includes('max_fund_amount') || filters[i].includes('min_fund_amount')) {
                continue;
            } 
            
            if (filters[i].includes(substringSymbol)) {
                splitString = filters[i].split(substringSymbol);
                symbol = substringSymbol;
            } else if (filters[i].includes(keywordSymbol)) {
                splitString = filters[i].split(keywordSymbol);
                symbol = keywordSymbol;
            } else {
                splitString = filters[i].split('=');
                symbol = EQUAL_SYMBOL;
            }

            key = splitString[0];

            if (splitString.length > 2) {
                const tempArr = [...splitString];
                tempArr.shift();

                const tempValue = tempArr.join('='); 
                values = tempValue.split(',').join(VALUE_SEPARATOR);
            } else {
                values = splitString[1].split(',').join(VALUE_SEPARATOR);
            }

            if (values.includes('^>=')) {
                symbol = '^>:'
                values = values.replace('^>=', '')
            } else if (values.includes('^<=')) {
                symbol = '^<:'
                values = values.replace('^<=', '')
            } else if (values.includes('^>')) {
                symbol = '^>^'
                values = values.replace('^>', '')
            } else if (values.includes('^<')) {
                symbol = '^<^'
                values = values.replace('^<', '')
            } else if (values.includes('^btw')) {
                symbol = ''
            } 
            
            filtersArray.push(`${key}${symbol}${values}`)
        }

    } else {
        return null
    }

    return filtersArray.join(FIELD_SEPARATOR)
}

/**
 * Method to convert a string containing encoded filters to a filter object
 * 
 * @param {String}   string (encoded)  
 * @example 'industry%5E%3A5%5E%2Cgeo_focus%5E%3A5662%5E%2Cdevelopment_stage%5E%3A1520' 
 * @returns {Object}   Returns a plain object with key(s):value(s)
 */

export const decodeFiltersValues = (urlFilters) => {
    let filters = {}

    decodeURIComponent(urlFilters).split(FIELD_SEPARATOR).map(item => {
        let obj = item.split(EQUAL_SYMBOL)
        let key = (obj && obj.length > 0) ? obj[0] : 'default'
        let value = obj[1].split(VALUE_SEPARATOR)
        
        filters[key] = value;
    })

    return filters
}