import isEmpty from 'lodash/isEmpty';

// api
import apiHelper from '/src/apis/apiHelper';

// const
import { ERROR_MESSAGES } from '/src/lib/errorMessages';
import { fundraisingOptions, numOfEmployees } from '../constants/formOptions';
import { fields } from '../constants/breakdownFields';



// Values are the old fields
// And keys are their new alternatives
let fieldsAlt = {
    startup: {
        name: 'name',
        description: 'desInput',
        product_video: 'videolink',
        development_stage: 'stage',
        business_type_id: 'type',
        number_of_founders: 'number',
        revenue_generating: 'revenue',
        hq_id: 'incorporated',
        linkedin: 'linkedin',
        facebook: 'facebook',
        twitter: 'twitter',
        website: 'website',
        android_app_url: 'androidAppUrl',
        ios_app_url: 'iosAppUrl',
        fundraising: 'funding',
        min_num_emp: 'howamny',
        max_num_emp: 'howamny',
        min_fund_amount: 'fundraising',
        max_fund_amount: 'fundraising',
        logo: 'logoId',
        cover_image: 'coverId',
        problem_solved: 'idea2',
        market_opportunity: 'idea3',
        competitors: 'idea4',
        monetization: 'idea5',
        challenges: 'idea6',
        owned_by: 'owned_by',
        city_id: 'city',
        year: 'year',
        date: 'date'
    },
    investor: {
        name: 'name',
        description: 'desInput',
        body: 'overview',
        video_link: 'videolink',
        hq_id: 'incorporated',
        city_id: 'city',
        linkedin: 'linkedin',
        facebook: 'facebook',
        twitter: 'twitter',
        website: 'website',
        min_ticket_size: 'min_ticket_value_final',
        max_ticket_size: 'max_ticket_value_final',
        logo: 'logoId',
        cover_image: 'coverId',
        owned_by: 'owned_by',
        type_id: 'investorT',
        min_num_emp: 'howamny',
        max_num_emp: 'howamny',
    },
    user: {
        first_name: 'fname',
        last_name: 'lname',
        type: 'investorT',
        location_id: 'location',
        facebook: 'facebook',
        skype: 'skype',
        twitter: 'twitter',
        linkedin: 'linkedin',
        phone: 'phone',
        occupation_description: 'whatido',
        startup_experience: 'startup',
        gender: 'gender',
        nationality_id: 'nationality'
    }
}

// replicate for corps and enablers
fieldsAlt.corporate = fieldsAlt.investor;
fieldsAlt.enabler   = fieldsAlt.investor;

// take old fields and assign them to their new alternatives
export const prepNewFields = (oldFields, entityType) => {

    let objReturn = {};

    for (const [key, value] of Object.entries(fieldsAlt[entityType])) {
        if (oldFields[value] || oldFields[value] === 0 || oldFields[value] === "") {

            // If value is being removed completely then we have to send null
            if (oldFields[value] === "") {
                oldFields[value] = null;
            }

            objReturn[key] = oldFields[value];
    
            if ((key == 'min_num_emp') || (key == 'max_num_emp')) {

                let index = parseInt(oldFields[value]);
                objReturn[key] = (key == 'min_num_emp') 
                    ? numOfEmployees[index].minValue 
                    : numOfEmployees[index].maxValue;

            } else if ((key == 'min_fund_amount') || (key == 'max_fund_amount')) {

                let index = parseInt(oldFields[value] - 1);
                objReturn[key] = (key == 'min_fund_amount') 
                    ? fundraisingOptions[index].minValue 
                    : fundraisingOptions[index].maxValue;

            } else if ((key == 'date') || (key == "year")) {

                if (oldFields.date && oldFields.year) {
                    objReturn.date_founded = `${oldFields.year}-${oldFields.date}-01`;
                }

            } else if (key == 'city_id') {

                if (!isEmpty(oldFields.city) && oldFields.city.value) {
                    objReturn[key] = oldFields.city.value;
                }
                
            }

        }
    }

    return objReturn;
}

// claim entity and add user to entity's team
export const claimEntity = async (owned_by, id, entityType) => {
    const payload = { owned_by };
    const error   = "Failed to claim the entity profile, please try again or contact our team if the issue persists";
    await apiHelper.handlePatch(`${entityType}/${id}/claim`, payload, error);
}

// todo: simplify these arguments
export const prepBulkOperations = (basicDetails, entity, id, editParams, isAddPage, pictureId) => {

    let bulkOperations = [];

    // these calls aren't for entity = user
    // user patch and logo edit are handled differently since we need to call userActions redux
    if (entity !== "user") {

        if (!isAddPage) {
            if (!isEmpty(basicDetails)) {
                bulkOperations.push(
                    apiHelper.handlePatch(`${entity}/${id}`, basicDetails, ERROR_MESSAGES.FAILED_TO_SAVE_DETAILS)
                );
            }
        }

        if (editParams.logo) {
            bulkOperations.push(apiHelper.updateFile(
                `${entity}/${id}/logo`, 'logo', editParams.logo, 
                ERROR_MESSAGES.FAILED_TO_SAVE_LOGO)
            );
        }

    }

    if (editParams.cover) {
        bulkOperations.push(apiHelper.updateFile(`${entity}/${id}/cover-image`, 'cover_image', editParams.cover, ERROR_MESSAGES.FAILED_TO_SAVE_COVER));
    }

    if (editParams.picture) {
        bulkOperations.push(apiHelper.saveFile(`${entity}/${id}/product-file`, 'product_files', editParams.picture, ERROR_MESSAGES.FAILED_TO_SAVE_PICTURE));

        if (pictureId) {
            bulkOperations.push(apiHelper.handleDelete(`${entity}/${id}/product-file/${pictureId}`, ERROR_MESSAGES.FAILED_TO_SAVE_PICTURE));
        }
    }

    let industryError = isAddPage ? ERROR_MESSAGES.FAILED_TO_SAVE_INDUSTRIES : ERROR_MESSAGES.FAILED_TO_UPDATE_INDUSTRIES;
    if (editParams.primaryFocus) {
        const tempObj = { industry_id: editParams.primaryFocus, is_primary: 1 };
        bulkOperations.push(apiHelper.handlePost(`${entity}/${id}/industry`, [tempObj], industryError));
    }

    if (editParams.subFocus) {
        const tempObj = { industry_id: editParams.subFocus, is_primary: 0 };
        bulkOperations.push(apiHelper.handlePost(`${entity}/${id}/industry`, [tempObj], industryError));
    }

    return bulkOperations;

}

export const getRangeId = (max, min, data) => {
    let fundId = 1;
    let value;

    for (let i = 0; i < data.length; i++) {
        value = data[i];
        if (value.maxValue === max && value.minValue === min) {
            fundId = value.id;
            break;
        }
    }

    return fundId;
}

/** within relationsObj
 * @param {Array, Object}   data        Data which has to added
 * @param {String}          dataProp    Data prop which API requires e.g. industry_id, location_id
 * @param {String}          fieldType   Field type e.g. industry, geo-focus etc.
 */
export const prepRelationAPIs = (relationsObj, entityType, entityId) => {

    let APICalls = [];
    entityType = entityType.toLowerCase();

    relationsObj.map(relation => {

        const { data, dataProp, fieldType } = relation;

        // prep error feedback
        let errorMessage = "";
        if (fieldType == "industry") {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_INDUSTRIES;
        } else if (fieldType == "geo-focus") {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_LOCATIONS;
        } else if (fieldType == "branch") {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_BRANCH;
        } else if (fieldType == 'business-type') {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_BUSINESSTYPE;
        } else if (fieldType == "support") {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_SUPPORT;
        } else if (fieldType == "institution") {
            errorMessage = ERROR_MESSAGES.FAILED_TO_SAVE_LINKED_INSTITUTIONS;
        }

        if (data && data.length > 0) {
            data.map(v => {

                // id = id of the relation-data
                // itemId = id of the entity, country, etc.

                if (fieldType === "startup-rel") {
                    if (v.itemId && !v.isDeleted) {
                        return; // ignore unchanged
                    } else if (v.isDeleted) {
                        v.id = v.relId;
                    } else {
                        // quick fix for add startup-rel as
                        // its handled differently from the other relations
                        v.itemId = v.id;
                        delete v.id; 
                    }
                }
    
                let tempObj = {};

                if (fieldType === "institution") {
                    tempObj['linked_id']   = v.linked_id
                    tempObj['linked_type'] = v.linked_type
                } else {
                    tempObj[dataProp] = v.itemId;
                }
    
                if (!v.id && !v.isDeleted) {
                    APICalls.push(apiHelper.handlePost(`${entityType}/${entityId}/${fieldType}`, [tempObj], errorMessage));
                } else if (v.id && v.isDeleted && fieldType === "institution") {
                    APICalls.push(apiHelper.handleDelete(`${entityType}/${entityId}/institution/${v.linked_id}/linked-type/${v.linked_type.toLowerCase()}`, errorMessage));
                } else if (v.id && v.isDeleted) {
                    APICalls.push(apiHelper.handleDelete(`${entityType}/${entityId}/${fieldType}/${v.id}`, errorMessage));
                }
    
            })
        }
    })

    return APICalls;

}

// prepare form fields data received from api, into the parameter names we're expecting
export const prepareFields = (data, entity) => {
    let obj = {};

    // fields shared between entities

    if (data.product_video) {
        obj.videolink = data.product_video;
    } else if (data.video_link) {
        obj.videolink = data.video_link;
    }

    // prep year/date founded
    // Get month and add 1, since options start from 1 instead of 0
    if (data.date_founded) {
        obj.date = new Date(data.date_founded).getMonth() + 1;
        if ((obj.date + '').length == 1) {
            obj.date = "0" + obj.date;
        }
        obj.year = new Date(data.date_founded).getFullYear();
    }

    obj.name          = data.name;
    obj.coverFile     = (!isEmpty(data.cover_image_data)) ? data.cover_image_data.name : '';
    obj.desInput      = data.description || '';
    obj.city          = !isEmpty(data.city_data) ? { value: data.city_data.id, label: data.city_data.name } : '';
    obj.stage         = !isEmpty(data.development_stage_data) ? data.development_stage_data.id : '';
    obj.revenue       = data.revenue_generating;
    obj.number        = data.number_of_founders;
    obj.incorporated  = data.hq_data ? data.hq_data.id : "";
    obj.funding       = data.fundraising;
    obj.fundraising   = getRangeId(data.max_fund_amount, data.min_fund_amount, fundraisingOptions);
    obj.howamny       = getRangeId(data.max_num_emp, data.min_num_emp, numOfEmployees);
    obj.idea2         = data.problem_solved;
    obj.idea3         = data.market_opportunity;
    obj.idea4         = data.competitors;
    obj.idea5         = data.monetization;
    obj.idea6         = data.challenges;
    obj.nid           = data.id;
    obj.linkedin      = data.linkedin || '';
    obj.facebook      = data.facebook || '';
    obj.twitter       = data.twitter || '';
    obj.website       = data.website || '';
    obj.iosAppUrl     = data.ios_app_url || '';
    obj.androidAppUrl = data.android_app_url || '';
    obj.logoFile      = (!isEmpty(data.logo_data)) ? data.logo_data.name : "";
    obj.typeArr       = data.business_type_data;
    obj.overview      = data.body || '';
    obj.ownedBy       = data.owned_by;

    // fields for investors, corporates, enablers

    if (entity !== "startup" && entity !== "user") {

        obj.investorT = data.type_data ? data.type_data.id : "";

        if (data.max_ticket_size) {
            if (data.max_ticket_size >= 1000000) {
                obj.max_ticket_value = (Math.round(data.max_ticket_size / 100000, -1) / 10)
                obj.max_ticket = 'Million'
            }
            else if (data.max_ticket_size >= 1000) {
                obj.max_ticket_value = (Math.round(data.max_ticket_size / 100, 1) / 10)
                obj.max_ticket = 'Thousand'
            }
        }
        
        if (data.min_ticket_size) {
            if (data.min_ticket_size >= 1000000) {
                obj.min_ticket_value = (Math.round(data.min_ticket_size / 100000, -1) / 10)
                obj.min_ticket = 'Million'
            }
            else if (data.min_ticket_size >= 1000) {
                obj.min_ticket_value = (Math.round(data.min_ticket_size / 100, 1) / 10)
                obj.min_ticket = 'Thousand'
            }
        }

    }

    if (entity === "user") {

        let primaryEmail = null;
        if (data.email_data) {
            primaryEmail = data.email_data.filter(v => v.is_primary);
        }

        obj.fname    = data.first_name;
        obj.lname    = data.last_name;
        obj.email    = primaryEmail && primaryEmail[0].email;
        obj.phone    = data.phone;
        obj.logoFile = (!isEmpty(data.profile_pic_data)) ? data.profile_pic_data.name : "";
        obj.location = data.location_id ? data.location_id : "";
        obj.whatido  = data.occupation_description;
        obj.startup  = data.startup_experience;
        obj.gender   = data.gender ? data.gender : "";

        obj.nationality = data.nationality_id ? data.nationality_id : "",
        obj.investorT   = data.type;
    }

    // initialize empty funding round fields
    fields.round.map((item) => {
        obj[item] = "";
    })

    fields.breakdown.map((item) => {
        obj["breakdown_" + item] = "";
    })

    fields.investor.map((item) => {
        obj["investor_" + item] = "";
    })

    obj.tempInvestor = "";

    return obj;
}

export const updateUser = (entityId, data, userProps) => {
    return new Promise((resolve, reject) => {
        apiHelper.handlePatch(`user/${entityId}`, data)
            .then(response => {

                const resp = response.data.data;

                const obj = {
                    token: userProps.token,
                    refresh_token: userProps.refresh_token,
                    isloggedin: true,
                    user: {
                        username: resp.username,
                        first_name: resp.first_name,
                        last_name: resp.last_name,
                        id: resp.id,
                        email: resp.email,
                        roles: userProps.roles,
                        status: resp.email_status
                    }
                }
                resolve(obj);

            }).catch(err => { reject(err) })
    })
}

export const updateUserLogo = (entityId, logo) => {
    return new Promise((resolve, reject) => {
        apiHelper.updateFile(
            `user/${entityId}/profile-pic`, 'profile_pic', logo, 
            ERROR_MESSAGES.FAILED_TO_SAVE_LOGO
        ).then(response => {
            resolve(response.data.data.path);
        }).catch(err => { reject(err) })
    })
}

export const changeFormPage = (toPage, state, validateFields) => {

    let { formPages } = state;
    let fromPage = null; // page user is leaving
    formPages.map((page, index) => {
        if (page.visible) {
            fromPage = index;
        }
    })

    // avoid validating form if moving to prev page
    let isGoingPrevious = (toPage < fromPage);
    if (isGoingPrevious || validateFields()) {
        formPages[fromPage].visible = false;
        formPages[toPage].visible = true;

        // reset errors
        state.errorMessages = [];
        state.urlError = false
        state.addround_error = false
        state.reqFields.map(item => {
            state[`${item.name}_error`] = false
        })

        let reqFields = []
        const isLastStep = toPage === formPages.length - 1

        if (isLastStep) {
            // add all required fields of the form for validation on the last step of the form
            for (const step of formPages) {
                if (step.reqFields) {
                    reqFields = [...reqFields, ...step.reqFields]
                }
            }
        } else {
            // load the required fields for the new page
            reqFields = formPages[toPage].reqFields;
        }
        return ({ state, formPages, reqFields });
    }

}