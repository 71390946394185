import isEmpty from 'lodash/isEmpty';

import { EXCEPTION_INDUSTRIES_AND, EXCEPTION_INDUSTRIES_SLASH, EXCEPTION_INVESTOR_TYPES } from '../src/lib/constants';
import { SSR_API_URL_NEW } from '../src/lib/endpoints';
import { prepareSelectParam } from '../src/lib/queryParams';
import AxiosServerInstanceClass from '../src/lib/axiosServerInstance';

export const fetchStartupDetails = async (slug, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    // Get the id from the slug string, example: startup-code-3223
    const id = parseInt(slug.split('-').pop());

    if (!Number.isInteger(id)) {
        return {};
    }

    // visible fields for logged in and logged out users
    const defaultFields = [
        'id',
        'name',
        'previous_name',
        'code',
        'logo_data',
        'cover_image_data',
        'website',
        'owned_by',
        'android_app_url',
        'ios_app_url',
        'is_active',
        'created_at',
        'description',
        'problem_solved',
        'product_video',
        'date_founded',
        'number_of_founders',
        'min_num_emp',
        'max_num_emp',
        'development_stage_data',
        'revenue_generating',
        'linkedin',
        'facebook',
        'twitter'
    ];

    // visible fields for logged in users only
    const loggedInFields = [
        'type_data',
        'hq_data',
        'min_ticket_size',
        'max_ticket_size',
        'industry_data',
        'branch_data',
        'business_type_data',
        'market_opportunity',
        'competitors',
        'monetization',
        'challenges',
        'min_fund_amount',
        'max_fund_amount',
        'fund_raised_data',
        'fundraising',
        'city_data',
        'fund_invested_data',
        'primary_industry_data',
        'last_date_raised',
        'total_fund',
        'number_of_rounds_raised',
        'number_of_investors',
    ];

    // if the token is empty, that means the user is logged out and will not be able to see the entire profile
    let startupFields;
    if (isEmpty(token)) {
        startupFields = prepareSelectParam(defaultFields);
    } else {
        startupFields = prepareSelectParam(loggedInFields.concat(defaultFields));
    }

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/startup/${id}/?fields=${startupFields}`);

        return (response.data.status == 200 && response.data.data.id) ? response.data.data : 404;
    } catch (error) {
        return 404;
    }
}


export const fetchICEDetails = async (slug, entityType, token = "", refreshToken = "") => {

    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    // Get the id from the slug string, example: corporate-code-3223
    const id = parseInt(slug.split('-').pop());

    if (!Number.isInteger(id)) {
        return {};
    }

    const endpointMap = {
        corporate: 'corporate',
        enabler: 'enabler',
        investor: 'investor'
    };

    const endpoint = endpointMap[entityType];
    const fields = prepareSelectParam(['id', 'name', 'code', 'description', 'body', 'logo_data',
        'cover_image_data', 'type_data', 'video_link', 'hq_data', 'city_data', 'linkedin',
        'facebook', 'twitter', 'website', 'min_ticket_size', 'max_ticket_size', 'industry_data',
        'branch_data', 'owned_by', 'rel_startup_cnt', 'created_at',
        'fund_raised_data', 'estimated_deployed_capital', 'estimated_deployed_startup', 'estimated_deployed_fund',
        'fund_of_fund_total_amount_raised', 'number_of_funds', 'number_of_investments', 'number_of_startups', 
        'last_date_invested', 'number_of_lps'
    ]);

    if (!endpoint) {
        return {};
    }

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/${endpoint}/${id}/?fields=${fields}`);
        return (response.data.status === 200 && response.data.data.id) ? response.data.data : 404;
    } catch (error) {
        return 404;
    }
};



export const fetchUserDetails = async (id, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    const fields = prepareSelectParam(['id', 'code', 'first_name', 'last_name', 'type', 'user_email_data', 'username', 'profile_pic_data', 'startup_experience', 'nationality_data', 'location_data', 'occupation_description', 'type_data', 'linkedin', 'facebook', 'twitter', 'created_at', 'phone', 'current_job_title', 'email_data', 'location_id', 'gender', 'nationality_id'])

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/user/${id}/?fields=${fields}`);

        return (response.data.status == 200 && response.data.data.id) ? response.data.data : 404;
    } catch (error) {
        return 404;
    }
}

export const fetchPersonDetails = async (id, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    const fields = prepareSelectParam([
        'id',
        'code',
        'first_name',
        'last_name',
        'type',
        'profile_pic_data',
        'experience',
        'nationality_id',
        'nationality_data',
        'country_id',
        'country_data',
        'occupation_description',
        'type_data',
        'linkedin',
        'facebook',
        'twitter',
        'current_job_title',
        'gender',
        'profile_pic',
        'status',
        'created_at'
    ])

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/people/${id}/?fields=${fields}`);

        return (response.data.status == 200 && response.data.data.id) ? response.data.data : 404;
    } catch (error) {
        return 404;
    }
}

export const fetchJobDetails = async (slug, token = "", refreshToken = "") => {

    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    // Get the id from the slug string, example: test-job-code-3223
    let id = parseInt(slug.split('-').pop())
    if (!Number.isInteger(id)) {
        return {}
    }

    let fields = prepareSelectParam(['id', 'code', 'title', 'type_data', 'location_data', 'requirement', 'description', 'status', 'published_at', 'department_id'])

    try {

        // fetch job info
        const resp = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/job/${id}?fields=${fields}`);
        if (resp.data.status != 200 || resp.data.data.id == null) {
            return 404;
        }
        let jobData = resp.data.data;

        // fetch department info
        const { department_id } = jobData;
        const dep_fields = prepareSelectParam(['description'])
        const url = `${SSR_API_URL_NEW}/magnitt-department/${department_id}?fields=${dep_fields}`
        const depData = await AxiosInstance.axiosInstance.get(url);
        if (depData.data.data) {
            jobData.departmentDesc = depData.data.data.description;
        }

        return { jobData };

    } catch (error) {
        return 404;
    }
}

export const fetchEntityIdFromCode = async (path, code, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        const url = `${SSR_API_URL_NEW}/${path}?fields=id^,code&filters=code^:${code}&limit=1`

        const response = await AxiosInstance.axiosInstance.get(url);
        const data = response.data.data;

        //check whether the id exists before sending
        return (data && data.length > 0) ? (data[0].id) : {};

    } catch (error) {
        console.log(error);
    }
}

export const fetchLocationByISOCode = async (isoCode, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/location?fields=id^,name&filters=iso_code^:${isoCode}`);
        const data = response.data.data;

        //check whether the name exists before sending
        return (data && data.length > 0) ? (data[0]) : {};

    } catch (error) {
        console.log(error);
    }
}

export const fetchIndustryByCode = async (industryCode, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        if (EXCEPTION_INDUSTRIES_SLASH.includes(industryCode)) {
            industryCode = industryCode.replace('-', '/')
        }

        if (EXCEPTION_INDUSTRIES_AND.includes(industryCode)) {
            industryCode = industryCode.replace('and', '&')
        }

        let industryCodeEncoded
        const decodedIndustry = decodeURIComponent(industryCode.toLowerCase())

        if (decodedIndustry == industryCode.toLowerCase()) {
            industryCodeEncoded = encodeURIComponent(industryCode.toLowerCase())
        } else {
            industryCodeEncoded = industryCode.toLowerCase()
        }

        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/industry?fields=id^,name&filters=code^:${industryCodeEncoded}`);
        const data = response.data.data;

        //check whether the name exists before sending
        return (data && data.length > 0) ? (data[0]) : {}

    } catch (error) {
        console.log(error);
    }
}

export const fetchInvestorTypeByCode = async (typeCode, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        let type

        if (EXCEPTION_INVESTOR_TYPES[typeCode]) {
            type = EXCEPTION_INVESTOR_TYPES[typeCode]
        } else {
            type = typeCode.replace(/[-]/g, ' ')
        }

        const investorResponse = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/taxonomy-type/36/taxonomy?fields=id^,name&filters=name^l:${type}`);
        const investorData = investorResponse.data.data;

        if (investorData && investorData.length > 0) {
            return investorData[0];
        }

        const enablerResponse = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/taxonomy-type/37/taxonomy?fields=id^,name&filters=name^l:${type}`);
        const enablerData = enablerResponse.data.data;

        if (enablerData && enablerData.length > 0) {
            return enablerData[0];
        }

        const corporateResponse = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/taxonomy-type/38/taxonomy?fields=id^,name&filters=name^l:${type}`);
        const corporateData = corporateResponse.data.data;

        if (corporateData && corporateData.length > 0) {
            return corporateData[0];
        }

        return {};

    } catch (error) {
        console.log(error);
    }
}

export const fetchStartupsCount = async (filters, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/startup/setting/count?filters=${filters}`);
        const data = response.data.data;
        const status = response.data.status;

        return status == 200 ? data : {};

    } catch (error) {
        console.log(error);
    }
}

export const fetchInvestorsCount = async (filters, token = "", refreshToken = "") => {
    const AxiosInstance = new AxiosServerInstanceClass(token, refreshToken);
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        const response = await AxiosInstance.axiosInstance.get(`${SSR_API_URL_NEW}/ecosystem/setting/count?filters=${filters}`);
        const data = response.data.data;
        const status = response.data.status;

        return status == 200 ? data : {};

    } catch (error) {
        console.log(error);
    }
}

/*
 * get past newsletters from mailchimp RSS feed
 * note: needs to be called on server-side, will return CORS error otherwise
 */

export const getPastNewsletters = async () => {
    const AxiosInstance = new AxiosServerInstanceClass();
    AxiosInstance.req();
    AxiosInstance.res();

    try {
        // note: the RSS feed URL is "http://us10.campaign-archive.com/feed?u=bbcacd3540b584c664319660e&id=45876afdd8", but it only returns 10 records, so instead we are calling mailchimp's archive directly (it returns an html page)
        const url = "https://us10.campaign-archive.com/home/?u=bbcacd3540b584c664319660e&id=45876afdd8";
        const response = await AxiosInstance.axiosInstance.get(url);
        return (response.data);
    } catch (error) {
        console.log(error);
    }
}
