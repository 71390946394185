import React from 'react';

// reusable
import ClearFilters from '/src/components/reusable/directories/filters/ClearFilters';
import DirectoryPaging from './DirectoryPaging';
import MagnittLoader from '/src/micro-frontends/MagnittLoader';

// constants
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';



/* this component displays magnitt loader / pagination / no results msg as needed */

const DirectoryFooter = (props) => {

    const { data, clearFilter, showTestData, user, count, isCustomMarketMap } = props;
    const hasAccess = (user.is_loggedin && (user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access) || user.roles.includes(MAGNITT_ACCESS_ROLES.limited_directory_access)));

    if (showTestData) {
        return (null);
    }

    // magnitt loader
    else if (data === false) {
        return (
            <MagnittLoader data={{ height: "300px", background: "#FFF" }} />
        )
    }

    // no results msg
    // avoid showing in custom market map view, as a different message will be visible
    else if (!data.length && !isCustomMarketMap) {
        return (
            <ClearFilters clearFilter={clearFilter} />
        )
    }

    // pagination
    else if (hasAccess && (data.length <= count)) {
        return (
            <DirectoryPaging
                // funcs
                filterValues={props.filterValues}
                setState={props.setState}
                // passed values
                page={props.page}
                count={count}
                limit={props.limit}
                loadingDirPage={props.loadingDirPage}
                tempFilter={props.tempFilter}
            />
        )
    }

    else {
        return (null);
    }

}

export default DirectoryFooter;
