import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Masonry from 'react-masonry-css'
import { hoverHelpers } from 'shared-helpers';

// api
import startupMarketMapApi from '/src/apis/startupMarketMapApi';

// helpers
import { prepareSelectParam, prepareFilterParam } from '/src/lib/queryParams';

// reusable
import MagnittLoader from '/src/micro-frontends/MagnittLoader';
import { VerifiedFundingNote } from '/src/pages/directories/reusable/VerifiedFundingNote';

// constants
import { ALLOWED_QUERY_OPERATOR } from '/src/lib/constants';
import { NO_DATA } from '/src/lib/messages';
import { MAGNITT_ACCESS_ROLES } from '../../../../lib/constants/userRoles';
import { ENTITY_STATUS } from '../../../../lib/constants';

class StartupMarketMapLandscape extends Component {

    constructor(props) {
        super(props);

        // set a default grouping if none was selected
        // (this only affects testapp maps where the 'group by' field was reset)
        if (props.group.length > 0) {
            this.view = props.group[0].name.replace(/ +/g, "");
        } else {
            this.view = "Country";
        }

        this.state = {
            page: 1,
            showPageLoading: false,
            showCardsLoading: false,
            isCountry: this.view === "Country" ? true : false,
            isPrimaryIndustry: this.view === "PrimaryIndustry" ? true : false,
            isSubIndustry: this.view === "SubIndustry" ? true : false,
            isBusinessType: this.view === "BusinessType" ? true : false,
            isLastStageRaised: this.view === "LastStageRaised" ? true : false,
            currentView: this.view,
            breakpointColumnsObj: {
                default: 3,
                1024: 2,
                600: 1
            },
            paramFilters: [
                { key: 'status', value: [ENTITY_STATUS.claimedOrAdded, ENTITY_STATUS.invited], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: "funding_verification_status_filter", value: [1], op: ALLOWED_QUERY_OPERATOR.equal }
            ]
        };
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            marketMapData: this.props.data.marketMapData,
            showPageLoading: true,
            filter: data.filter,
            limit: this.props.data.limit
        }, () => {
            this.getNetworkMap();
        });
    }

    getNetworkMapFilters() {
        const { paramFilters, filter } = this.state;
        let customFilters = prepareFilterParam(filter);
        let defaultFilters = prepareFilterParam(paramFilters);

        let filters = customFilters ? defaultFilters + `^,${customFilters}` : defaultFilters;
        return filters;
    }

    async getNetworkMap() {
        const { currentView, limit, page } = this.state;
        let responseData, paramFields, sort, order;
        let filters = this.getNetworkMapFilters();

        switch (currentView) {
        case 'Country':
            paramFields = ['country_id', 'country_name', 'country_cnt'];
            sort = `country_cnt^:desc`;
            break;
        case 'PrimaryIndustry':
            paramFields = ['primary_industry_id', 'primary_industry_name', 'industry_cnt'];
            sort = `industry_cnt^:desc`;
            break;
        case 'SubIndustry':
            paramFields = ['sub_industry_id', 'sub_industry_name', 'industry_cnt'];
            sort = `industry_cnt^:desc`;
            break;
        case 'BusinessType':
            paramFields = ['business_type_id', 'business_type_name', 'business_type_cnt'];
            sort = `business_type_cnt^:desc`;
            break;
        case 'LastStageRaised':
            paramFields = ['last_stage_id', 'last_stage_name', 'last_stage_cnt'];
            sort = `last_stage_cnt^:desc`;
            break;
        }

        order = this.props.order;
        if (order && order.field == 'total_fund' && order.order == 'desc') {
            sort = sort + '^,fund_raised^:desc';
        } else if (order && order.field == 'total_fund' && order.order == 'asc') {
            sort = sort + '^,fund_raised^:asc';
        }  else if (order && order.field == 'last_stage_raised' && order.order == 'desc') {
            sort = '^,last_stage_raised^:desc';
        } else {
            sort = sort + '^,last_date_raised^:desc';
        }

        const fields = prepareSelectParam(paramFields);
        await startupMarketMapApi.getStartupDirectoryLandscape(fields, filters, limit, page, sort).then((response) => {
            if (!isEmpty(response)) {
                responseData = response;
            }
        })

        this.setState(prevState => ({
            showCardsLoading: false,
            showPageLoading: false,
            marketMapData: {
                ...prevState.marketMapData,
                data: [...prevState.marketMapData.data, ...responseData.records],
                count: responseData.count
            }
        }));

    }

    changeView(currentView) {
        let obj = {};
        obj[currentView] = true;

        this.setState(prevState => ({
            isCountry: false,
            isPrimaryIndustry: false,
            isSubIndustry: false,
            isBusinessType: false,
            isLastStageRaised: false,
            showPageLoading: true,
            currentView: currentView.replace('is', ''),
            page: 1,
            marketMapData: {
                ...prevState.marketMapData,
                data: [],
                count: 0
            }
        }), () => {
            this.setState(obj);
            this.getNetworkMap();
        })

        //remove landscape tooltips when tab is changed
        $(".popover").remove();
    }

    showMoreCards() {
        let newPage = this.state.page;
        newPage++
        this.setState({
            page: newPage,
            showCardsLoading: true
        }, () => {
            this.getNetworkMap();
        })
    }

    showMoreItems = (card, index) => {
        const { currentView, marketMapData } = this.state;
        const fieldParams = ['id', 'name', 'code', 'logo_data'];
        const fields = prepareSelectParam(fieldParams);
        let paramFilters = [], categoryFilters;
        let filters = this.getNetworkMapFilters();

        // category filter
        if (card.category_id) {
            if (currentView === 'Country') {
                paramFilters.push(
                    { key: 'hq_id', value: [card.category_id], op: ALLOWED_QUERY_OPERATOR.equal }
                )
            } else if (currentView === 'PrimaryIndustry') {
                paramFilters.push(
                    { key: 'primary_industry_filter', value: [card.category_id], op: ALLOWED_QUERY_OPERATOR.equal }
                )
            } else if (currentView === "SubIndustry") {
                paramFilters.push(
                    { key: 'sub_industry_filter', value: [card.category_id], op: ALLOWED_QUERY_OPERATOR.equal }
                )
            } else if (currentView === 'BusinessType') {
                paramFilters.push(
                    { key: 'business_type', value: [card.category_id], op: ALLOWED_QUERY_OPERATOR.equal }
                )
            } else if (currentView === 'LastStageRaised') {
                paramFilters.push(
                    { key: 'last_stage_filter', value: [card.category_id], op: ALLOWED_QUERY_OPERATOR.equal }
                )
            }
        }

        categoryFilters = prepareFilterParam(paramFilters)
        filters = categoryFilters ? filters + `^,${categoryFilters}` : filters;

        // Dynamic sort based on user input
        let sort = '';
        let order = this.props.order;
        if (order && order.field == 'total_fund' && order.order == 'desc') {
            sort = 'total_fund^:desc';
        } else if (order && order.field == 'total_fund' && order.order == 'asc') {
            sort = 'total_fund^:asc';
        } else {
            sort = 'last_date_raised^:desc';
        }

        let page = card.category_page ? card.category_page + 1 : 2;
        let remaining = card.category_cnt - (page - 1) * 12;
        let limit = 12;

        let mapData = marketMapData.data;
        mapData[index].showItemsLoading = true;
        this.setState(prevState => ({
            marketMapData: {
                ...prevState.marketMapData,
                data: mapData
            }
        }));

        startupMarketMapApi.getStartups(fields, filters, page, sort, limit).then((response) => {
            if (!isEmpty(response)) {
                mapData = marketMapData.data;
                mapData[index].category_page = page;
                mapData[index].showItemsLoading = false;

                let responseData = remaining > 12 ? response.data.data : response.data.data.slice(0, `${remaining}`);
                responseData.forEach((entity) => {
                    mapData[index].entity_data.push(entity);
                });

                this.setState(prevState => ({
                    marketMapData: {
                        ...prevState.marketMapData,
                        data: mapData
                    }
                }));
            }
        });
    }

    imgMissing(event) {
        event.target.style.opacity = 0;
        event.target.parentNode.style['border-radius'] = '7px';
        event.target.parentNode.style['background-color'] = '#F0F0F0';
    }

    renderItemImage(item) {
        if (item.logo_data.path) {
            let img = encodeURI(item.logo_data.path);
            return (<img className="entity-image" itemProp="image" src={img} onError={(event) => this.imgMissing(event, item)} width="100" height="100" loading="lazy" />)
        } else {
            return (<div className="entity-image-div">{item.name.charAt(0).toUpperCase()}</div>)
        }
    }

    renderCardItems(cardItems) {
        const { user } = this.props;
        let userHasDirectoryAccess = false;
        if (user && user.roles && user.roles.length > 0) {
            userHasDirectoryAccess = (user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access) || user.roles.includes(MAGNITT_ACCESS_ROLES.limited_directory_access))
        }

        let items = [];
        cardItems.forEach((item) => {
            item.type = "startup";
            items.push(
                <span key={`item-` + item.id}>
                    <a className="map-card__entity" target="_blank" rel="noopener noreferrer" href={`/startups/` + item.code + `-` + item.id}>
                        <div className="entity-icon" onMouseEnter={(event) => hoverHelpers.handleEntityHover(item, event, userHasDirectoryAccess)} onMouseLeave={() => hoverHelpers.handleMouseOut()}>
                            {this.renderItemImage(item)}
                        </div>
                    </a>
                </span>
            )
        });
        return items;
    }

    renderMapCards() {
        let cards = this.state.marketMapData.data;
        let cardsRender = [];
        cards.forEach((card, index) => {
            cardsRender.push(
                <section className="map-card fadezoom" key={`card-` + card.category_name}>
                    <div className="map-box card">
                        <div className="card__header">
                            <div className="card__title">
                                {card.category_name}
                                <span className="float-right">({card.category_cnt})</span>
                            </div>
                        </div>
                        <div className="card__content">
                            <div className="map-card__items">
                                {this.renderCardItems(card.entity_data)}
                            </div>
                            {card.category_cnt > card.entity_data.length &&
                                <div className="card__more">
                                    <a onClick={() => this.showMoreItems(card, index)}>
                                        {card.showItemsLoading &&
                                            <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                        }
                                        Show More
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            )
        });

        return cardsRender;
    }

    render() {

        const { group, tabIsVisible } = this.props;
        const { showPageLoading, breakpointColumnsObj, marketMapData, showCardsLoading } = this.state;
        const hasMapData = (marketMapData && marketMapData.data.length > 0);

        return (
            <div className="network-map-main network-map-cards">

                <VerifiedFundingNote entityType="startup" />

                {/* display 'groups' in same order as user's selection */}
                {/* hide if only 1 grouping button is present */}
                {(group.length > 1) &&
                    <div className="network-map-groups">
                        {group.map((grp, index) => {
                            let code = "is" + grp.name;
                            code = code.replace(/\s+/g, '');
                            return (
                                <span key={`group-` + index} onClick={() => this.changeView(code)} className={"group-buttons " + (this.state[code] ? 'active' : 'inactive')}>
                                    {grp.name}
                                </span>
                            )
                        })}
                    </div>
                }

                {showPageLoading && tabIsVisible &&
					<MagnittLoader data={{ height: "300px", background: "transparent" }} />
                }

                {hasMapData &&
                    <div className="map-card-wrapper">
                        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {this.renderMapCards()}
                        </Masonry>
                    </div>
                }

                {!showPageLoading && !hasMapData &&
                    <div className="no-chart-data">
                        <h6>{NO_DATA}</h6>
                    </div>
                }

                {this.props.view !== 'preview' && !showPageLoading && marketMapData && marketMapData.data.length < marketMapData.count &&
                    <div className="d-flex justify-content-center mt-4" >
                        <a className="btn btn-main" onClick={() => this.showMoreCards()}>
                            {showCardsLoading ?
                                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                :
                                <i className="fas fa-arrow-circle-down mr-2"></i>
                            }
                            Show More
                        </a>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps)(StartupMarketMapLandscape);
