export const ERROR_MESSAGES = {
    FAILED_TO_SAVE_INDUSTRIES: 'Your startup was added successfully but the industry information was not saved, edit your profile to add the industry information again',
    FAILED_TO_SAVE_BUSINESSTYPE: 'Your startup was added successfully but the business type information was not saved, edit your profile to add the business type information again',
    FAILED_TO_SAVE_SUPPORT: 'Your startup was added successfully but the support information was not saved, edit your profile to add the support information again',
    FAILED_TO_SAVE_PRODUCT_FILE: 'Your startup was added successfully but the product file information was not saved, edit your profile to add the product file information again',
    FAILED_TO_SAVE_BRANCH: 'Your startup was added successfully but the branch information was not saved, edit your profile to add the branch information again',
    FAILED_TO_SAVE_LOCATIONS: 'Your startup was added successfully but the location information was not saved, edit your profile to add the location information again',
    FAILED_TO_SAVE_FUNDING: 'Your startup was added successfully but the funding information was not saved, edit your profile to add the funding information again',
    FAILED_TO_SAVE_TEAM: 'Your startup was added successfully but the team information was not saved, edit your profile to add the team information again',
    FAILED_TO_SAVE_TEAM_INVITATIONS: 'Your startup was added successfully but the team invitation information was not saved, edit your profile to add the team invitation information again',
    FAILED_TO_SAVE_LINKED_INSTITUTIONS: 'Your startup was added successfully but the institution associations was not saved, edit your profile to add the institution associations again',
    FAILED_TO_SAVE_DETAILS: 'Failed to update profile data, please try again',
    FAILED_TO_SAVE_LOGO: 'Failed to update logo, please try again',
    FAILED_TO_SAVE_COVER: 'Failed to update cover image, please try again',
    FAILED_TO_SAVE_PICTURE: 'Failed to update product picture, please try again',
    FAILED_TO_UPDATE_INDUSTRIES: 'Failed to update industry information, please try again',
    ACCESS_DENIED_FILE: 'Access denied to the file',
    FAILED_TO_CLAIM_STARTUP: 'Failed to claim the startup profile, please try again',
    GENERAL_SHORT_ERROR_MSG: 'Oops! something went wrong',
    UNAUTHORIZED_ERROR_MSG: 'Unauthorized access!',
    DELETE_CARD_SUBSCRIPTION_ERROR: 'Card cannot be deleted as there is an active subscription.',
    UNSAVED_WARNING: 'You have unsaved changes! Please click save or cancel first before adding the funding round'
}